import React from 'react';

import { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Table, TableBody, TableCell,TableSortLabel, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

import { DeleteProduct } from '.././Store';

import { ShakiContext } from "../store/ShakiProvider";


function LunaProducts() {

	const { getRemoteConf, search, GetLunaProducts } = React.useContext(ShakiContext);

	const title = 'Produits Luna';

	const [luna_products, setLunaProducts] = useState([]);

	const [sort, setSort] = useState('asc');
	const [orderBy, setOrderBy] = useState('brand');
	const [luna_categories, setLunaGategories] = useState({});
	const [luna_brands, setLunaBrands] = useState({});

	const classes = useStyles();


	useEffect(() => {
		setLunaGategories(getRemoteConf('luna_categories'));
		setLunaBrands(getRemoteConf('luna_brands'));
	}, []);


	useEffect(() => {

		GetLunaProducts((docs) => {

			if (docs === null) return;

			const list = [];
			docs.forEach(doc => {
				list.push(doc.data());
			});

			setLunaProducts(list);
		});

	}, []);


	var list = luna_products.filter((row) => {
		return (search === '' || (row.brand !== undefined && row.brand.toLowerCase().search(search.toLowerCase()) !== -1) || (row.name !== undefined && row.name.toLowerCase().search(search.toLowerCase()) !== -1));
	});

	var del = (e) => {
		DeleteProduct(e);
	}


	const headCells = [
		{id : 'brand', numeric : false, disablePadding : false, label : 'Marque'},
		{id : 'name', numeric : false, disablePadding : false, label : 'Nom'},
		{id : 'category', numeric : false, disablePadding : false, label : 'Catégorie'},
	];


	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && sort === 'asc';
		setSort(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};


	function getComparator(order, orderBy) {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
	};


	function stableSort(array, comparator) {

		array.sort((a, b) => {
			const order = comparator(a, b);
			if (order !== 0) {
				return order;
			}
			return a - b;
		});

		return array;
	}


	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Dashboard</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{paddingBottom : 40}}>{title}</h2>

			<div style={{width : '100%', display : 'flex', flexDirection: 'row', justifyContent : 'space-between', paddingBottom : 10}}>
				<div>

				</div>
				<div>
					<Button component={Link}  to={'/luna_products/new'} variant="outlined">
						Nouveau produit
					</Button>
				</div>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>#</StyledTableCell>
							{headCells.map((headCell) => (
								<StyledTableCell
									key={headCell.id}
									align={headCell.numeric ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'default'}
									sortDirection={orderBy === headCell.id ? sort : false}
								>
									<StyledTableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? sort : 'asc'}
										onClick={createSortHandler(headCell.id)}
									>
										<b>{headCell.label}</b>
										{orderBy === headCell.id ? (
											<span className={classes.visuallyHidden}>
												{sort === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</span>
										) : null}
									</StyledTableSortLabel>
								</StyledTableCell>
							))}
							<StyledTableCell>#</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{stableSort(list, getComparator(sort, orderBy)).map((row, i) => (
						<StyledTableRow key={row.sku}>

							<StyledTableCell>
								<Avatar
									src={row.picture_1}
									style={{ width: 105, height: 70 }}
									variant='rounded'
									component='span'
								/>
							</StyledTableCell>

							<StyledTableCell>
								{i + 1}
							</StyledTableCell>

							<StyledTableCell>{luna_brands[row.brand]}</StyledTableCell>
							<StyledTableCell>
								<Link to={'/luna_products/' + row.id}>{row.name}</Link>
							</StyledTableCell>
							<StyledTableCell>{luna_categories[row.category]}</StyledTableCell>
							<StyledTableCell>
								{row.id === 'o' &&
								<Button onClick={(e) => del(row.id)} color="primary" size="small" style={{minWidth : 30}}>
									<DeleteIcon />
								</Button>
								}
							</StyledTableCell>
						</StyledTableRow>

						))}
					</TableBody>
				</Table>
			</TableContainer>

		</div>
	);
}

const StyledTableCell = withStyles((theme) => ({
	head : {

	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({

}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});

export default React.memo(LunaProducts);


