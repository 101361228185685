import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell,TableSortLabel, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

import { DeleteProduct } from '.././Store';
import { GetImagePath } from '.././Utils';

import { ShakiContext } from "../store/ShakiProvider";

function Products() {

	const { products, search, remote_config, getRemoteConf } = useContext(ShakiContext);

	const title = 'Produits';
	const classes = useStyles();

	const [list, setList] = useState([]);
	const [sort, setSort] = useState('asc');
	const [orderBy, setOrderBy] = useState('sku');
	const [packaging_types, setPackagingTypes] = useState({});


	useEffect(() => {

		var a = [];

		for (var i in products) {
			var product = products[i];
			if (product.sub_recipe) continue;
			if (product.disabled === true) continue;
			a.push(product);
		}

		setList(a);

	}, [products]);


	useEffect(() => {
		setPackagingTypes(getRemoteConf('packaging_types'));
	}, []);


	var del = (e) => {
		DeleteProduct(e);
	};


	const headCells = [
		{id : 'sku', numeric : false, disablePadding : false, label : 'SKU'},
		{id : 'brand', numeric : false, disablePadding : false, label : 'Marque'},
		{id : 'category', numeric : false, disablePadding : false, label : 'Catégorie'},
		{id : 'name', numeric : false, disablePadding : false, label : 'Nom'},
	];


	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && sort === 'asc';
		setSort(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		};
		if (b[orderBy] > a[orderBy]) {
			return 1;
		};
		return 0;
	};


	function getComparator(order, orderBy) {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
	};


	function stableSort(array, comparator) {

		array.sort((a, b) => {
			const order = comparator(a, b);
			if (order !== 0) {
				return order;
			}
			return a - b;
		});

		return array;
	};


	var filtered_list = list.filter((row) => {

		if (search === '') return true;
		console.log(row.search_index)
		if (row.search_index.toLowerCase().search(search.toLowerCase()) !== -1) return true;

		return false;
	});


	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Dashboard</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ paddingBottom: 40 }}>{title}</h2>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>#</StyledTableCell>
							{headCells.map((headCell) => (
								<StyledTableCell
									key={headCell.id}
									align={headCell.numeric ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'default'}
									sortDirection={orderBy === headCell.id ? sort : false}
								>
									<StyledTableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? sort : 'asc'}
										onClick={createSortHandler(headCell.id)}
									>
										<b>{headCell.label}</b>
										{orderBy === headCell.id ? (
											<span className={classes.visuallyHidden}>
												{sort === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</span>
										) : null}
									</StyledTableSortLabel>
								</StyledTableCell>
							))}
							<StyledTableCell>Cond.</StyledTableCell>
							<StyledTableCell>Avant</StyledTableCell>
							<StyledTableCell>Arrière</StyledTableCell>
							<StyledTableCell>#</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{stableSort(filtered_list, getComparator(sort, orderBy)).map((row, i) => (
						<StyledTableRow key={row.sku}>

							<StyledTableCell>
								<Avatar src={GetImagePath(row.images, 'main')} className={classes.large} style={{ width: 105, height: 70 }} variant='rounded' component='span'/>
							</StyledTableCell>

							<StyledTableCell>
								{i + 1}
							</StyledTableCell>

							<StyledTableCell component="th" scope="row">
								<Link to={'/products/' + row.id}>{row.sku}</Link>
							</StyledTableCell>

							<StyledTableCell>{row.disabled ? '🔴' : '' } {row.brand}</StyledTableCell>

							<StyledTableCell>{row.category}</StyledTableCell>

							<StyledTableCell>{row.name}</StyledTableCell>

							<StyledTableCell>{(row.packaging !== undefined && row.packaging.type !== undefined && row.packaging.type !== '' && packaging_types[row.packaging.type] !== undefined) ? packaging_types[row.packaging.type].name : ''}</StyledTableCell>
							<StyledTableCell>{row.packaging !== undefined ? row.packaging.format : ''}</StyledTableCell>
							<StyledTableCell>{row.packaging !== undefined ? row.packaging.back_format : ''}</StyledTableCell>

							<StyledTableCell>
								{row.toto !== undefined &&
								<Button onClick={(e) => del(row.id)} color="primary" size="small" style={{minWidth : 30}}>
									<DeleteIcon />
								</Button>
								}
							</StyledTableCell>
						</StyledTableRow>

						))}
					</TableBody>
				</Table>
			</TableContainer>

		</div>
	);
}

export default React.memo(Products);

const StyledTableCell = withStyles((theme) => ({
	head : {

	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({

}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	large: {
		width: 80,
		height: 80,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});
