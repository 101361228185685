import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";

import { Sort } from '.././Utils';


function Sales(data) {

	var { shop_id } = useParams();
	var { include } = data;

	const title = 'Déclaration des ventes';

	const classes = useStyles();

	const [sales, setSales] = useState([]);

	useEffect(() => {

		return firebase.firestore().collection('sales_report').where('shop_id', '==', shop_id).onSnapshot((querySnapshot) => {

			if (querySnapshot === null) return;

			var list = [];
			querySnapshot.forEach((doc) => {
				var data = doc.data();
				data.date = (data.year * 100) + data.month;
				list.push(data);
			});

			setSales(list.sort((a, b) => Sort(a, b, 'date', 'desc')));
		});

	}, [shop_id]);


	return (
		<div>

			{(!include && shop_id !== undefined) &&
				<>
					<Breadcrumbs aria-label="breadcrumb" style={{paddingBottom : 20}}>
						<Link to='/'>Accueil</Link>
						<Typography>{title}</Typography>
					</Breadcrumbs>
				</>
			}

			<h2 style={{ paddingBottom: 40 }}>{title}</h2>

			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent : 'space-between', paddingBottom : 10}}>
				<div>
					<Button component={Link} to={'/shops/' + shop_id + '/newsale'} variant='outlined' color='default'>
						Nouvelle déclaration
					</Button>
				</div>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell>Mois</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{sales.map((row, index) => (

						<StyledTableRow key={'line_' + index}>

							<StyledTableCell component="th" scope="row">
								<Link to={'/shops/' + shop_id + '/sale/' + row.id}>Ventes du {row.month}/{row.year}</Link> {row.external_reference}
							</StyledTableCell>

						</StyledTableRow>

						))}
					</TableBody>

				</Table>

			</TableContainer>
		</div>
	);
}

export default React.memo(Sales);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});
