import React from 'react';

import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { TextField, Typography, Breadcrumbs, Button, IconButton, Select, InputLabel, FormControl} from '@material-ui/core';
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';

import { UsersContext } from "../store/UsersProvider";

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";

import { WeekDays, GetOrderKey } from '.././Utils';
import { setRoutesConf, getRoutesConfs } from '.././Store';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'


function Conf() {

	var { getRemoteConf, setOpenSnackBar } = useContext(ShakiContext);
	var { shops } = useContext(ShopsContext);

	const [cards, setCards] = useState([]);
	const [cards_hash, setCardsHash] = useState({});
	const [selected_day, setSelectedDay] = useState(0);
	const [input_value, setInputValue] = useState({});
	const [working_hours, setWorkingHours] = useState({});
	const [delivery_operators, setDeliveryOperators] = useState({});


	useEffect(() => {
		setWorkingHours(getRemoteConf('working_hours'));
		setDeliveryOperators(getRemoteConf('delivery_operators'));
	}, []);


	useEffect(() => {

		return getRoutesConfs((docs) => {

			var c = [];
			var h = {};
			docs.forEach((doc) => {

				var shift = doc.data();
				h[doc.id] = JSON.stringify(doc.data());

				if (c[parseInt(shift.day) - 1] === undefined) c[parseInt(shift.day) - 1] = [];
				c[parseInt(shift.day) - 1].push(shift);
			});

			setCards(c);
			setCardsHash(h);
		})
	}, []);


	const switchDay = (day) => {
		setSelectedDay(day)
	}


	const moveCard = useCallback((dragIndex, hoverIndex, key, day, shift) => {

		const dragCard = cards[day][shift].list[dragIndex];

		cards[day][shift].list.splice(dragIndex, 1);
		cards[day][shift].list.splice(hoverIndex, 0, dragCard)
		setCards(JSON.parse(JSON.stringify(cards)));

	}, [cards]);


	const save = (v = 1) => {

		for (var day in cards) {
			for (var shift in cards[day]) {
				//if (cards[day][shift].week === undefined) cards[day][shift].week = DateTime.local().weekNumber;
				//delete cards[day][shift].version;
				if (JSON.stringify(cards[day][shift]) !== cards_hash[cards[day][shift].id]) {
					setRoutesConf(cards[day][shift].id, cards[day][shift]).then((doc) => {
						setOpenSnackBar(true)
					});
				}
			}
		}
	}


	const getProduction = () => {

		var hash = {};

		loop1:
		for (var i in cards) {

			loop2:
			for (var shift in cards[i]) {

				var day = cards[i][shift].day

				if (cards[i][shift].pickup_hour === undefined) continue loop2;
				var pickup_hour_arr = cards[i][shift].pickup_hour.split(':');
				var pickup_hour = parseInt(pickup_hour_arr[0]) + parseInt(pickup_hour_arr[1])/100

				loop3:
				for (var j in cards[i][shift].list) {

					var current = cards[i][shift].list[j];
					var key = current.shop_id;

					var day_prod = day - 1;

					loop4:
					for (var k in working_hours[day]) {

						if (working_hours[day][k].end < pickup_hour) {
							day_prod = day;
							break loop4;
						}
					}

					if (hash[key] === undefined) hash[key] = [];

					hash[key].push({
						day: day,
						day_prod: day_prod,
						shift: shift
					});
				}
			}
		}


		loop1:
		for (var day in cards) {

			day = parseInt(day);

			loop2:
			for (var shift in cards[day]) {

				if (cards[day][shift].pickup_hour === undefined) continue loop2;

				loop3:
				for (var j in cards[day][shift].list) {

					var current = cards[day][shift].list[j];

					if (shops[current.shop_id].invoicing === undefined) continue loop3;
					if (shops[current.shop_id].invoicing !== undefined && shops[current.shop_id].invoicing.type !== undefined && shops[current.shop_id].invoicing.type !== 'invoice_revshare') continue loop3;

					cards[day][shift].list[j].order_days = [];

					//if (current.shop_id === 'e7792a5cd2d4aaa604d5a050a6d21135bf1c8cf53ea') console.log(hash[current.shop_id])

					loop4:
					for (var i = 0; i < hash[current.shop_id].length; i++) {

						if (parseInt(hash[current.shop_id][i].day) === (parseInt(day) + 1) && hash[current.shop_id][i].shift === shift) {

							var next = i + 1;
							var nb = hash[current.shop_id][next] !== undefined ? (hash[current.shop_id][next].day_prod - hash[current.shop_id][i].day_prod) : ((7 + parseInt(hash[current.shop_id][0].day_prod)) - hash[current.shop_id][i].day_prod);

							var postpone = hash[current.shop_id][i].day === hash[current.shop_id][i].day_prod ? 1 : 0;

							for (var k = 0; k < nb; k++) {

								var new_day = (parseInt(day + k + postpone) <= 6) ? parseInt(day + k + postpone) : parseInt(day + k + postpone) - 7;

								if (shops[current.shop_id].opening_days === undefined || shops[current.shop_id].opening_days[new_day].open === true) {
									cards[day][shift].list[j].order_days.push(new_day);
								}
							}
						}
					}
				}
			}
		}
	};


	useEffect(() => {

		if (shops === null) return;

		getProduction();

	}, [shops, cards]);



	const handleDrop = useCallback((shift, day, item) => {

		console.log('handleDrop', 'shift>', shift, 'day>', day, item);
		cards[day][shift].list.splice(item.index, 1);

		setCards(JSON.parse(JSON.stringify(cards)));

    }, [cards]);


    const addShift = (day) => {

    	cards[day].push({
    		id: GetOrderKey(10),
    		name: 'new',
    		list: [],
    		day: day + 1
    	});

		setCards(JSON.parse(JSON.stringify(cards)));

		return true;
    }


    const addShop = (day, shift) => {

    	console.log('addShop', day, shift, cards[day][shift]);

    	if (input_value[shift] === undefined || input_value[shift] === null) {
    		console.log('nothing to add');
    		return false;
    	}

    	var item = input_value[shift];

		// looking in all shift's day
		for (var i in cards[day]) {

			for (var j in cards[day][i].list) {

				var current = cards[day][i].list[j];

				if (current.shop_id === item.id && cards[day][shift].pickup_hour === cards[day][i].pickup_hour) {

					console.log('found it in day ' + day + ', shift ' + i + ', position ' + j);
					cards[day][i].list.splice(j, 1);

					break;
				}
			}
		}

		cards[day][shift].list.push({
			shop_id : item.id
		});

		getProduction();

		setCards(JSON.parse(JSON.stringify(cards)));

		input_value[shift] = null;
		setInputValue({...input_value});

		return true;
	}

	const setShiftName = (index_day, index_shift, value) => {
		cards[index_day][index_shift].name = value;
		setCards(JSON.parse(JSON.stringify(cards)));
	}

	const setPickupAddress = (index_day, index_shift, value) => {
		cards[index_day][index_shift].pickup_address = value;
		setCards(JSON.parse(JSON.stringify(cards)));
	}

	const setPickupHour = (index_day, index_shift, value) => {
		cards[index_day][index_shift].pickup_hour = value;
		setCards(JSON.parse(JSON.stringify(cards)));
	}

	const setOperator = (index_day, index_shift, value) => {
		cards[index_day][index_shift].operator = value;
		setCards(JSON.parse(JSON.stringify(cards)));
	}


	const renderDay = (day, index_day) => {

		var key = 'day_' + index_day;

		return (
			<div key={key} style={{display : 'flex', alignItems : 'center', paddingBottom : 30}}>
				<div style={{display : 'flex'}}>
					{day.map((shift, i) => renderShift(shift, i, index_day))}
				</div>
				<IconButton onClick={(e) => {addShift(selected_day)}} aria-label="add" size="medium">
					<AddIcon fontSize="inherit"/>
				</IconButton>
			</div>
		);
	};

	const renderShift = (shift, index_shift, index_day) => {

		var key = 'shift_' + index_day + '_s' + index_shift;

		return (
			<div key={key} style={{display : 'flex', flexDirection : 'column', marginRight : 10, width : 250}}>

				<div style={{marginBottom: 10}}>
					<TextField
						disabled
						label="id"
						style={{ width: '100%', marginBottom: 10 }}
						defaultValue={shift.id}
					/>
					<TextField
						label="Nom"
						style={{width : '100%'}}
						defaultValue={shift.name}
						onChange={(e) => {setShiftName(index_day, index_shift, e.target.value)}}
					/>
				</div>

				<div style={{ display: 'flex', marginBottom: 10 }}>

					<FormControl style={{ width: '33%' }} >
						<InputLabel htmlFor={'operator_' + key}>Opérateur</InputLabel>
						<Select
							native
							value={shift.operator}
							onChange={(e) => {setOperator(index_day, index_shift, e.target.value);}}
							inputProps={{
								name: 'Opérateur',
								id: 'operator_' + key,
							}}
						>
							<option value=''>--</option>
							{Object.entries(delivery_operators).map((operator, i) => (
								<option value={operator[0]}>{operator[1].name}</option>
							))}
						</Select>
					</FormControl>


					<FormControl style={{ width: '33%', marginLeft: 15 }} >
						<InputLabel htmlFor={'pickup_' + key}>Pickup</InputLabel>
						<Select
							native
							value={shift.pickup_address}
							onChange={(e) => {setPickupAddress(index_day, index_shift, e.target.value);}}
							inputProps={{
								name: 'Pickup',
								id: 'pickup_' + key,
							}}
						>
							<option value=''>--</option>
							<option value='kitchen'>kitchen</option>
							<option value='stef-vitrolles'>Stef Vitrolles</option>
							<option value='stef-brignais'>Stef Brignais</option>
							<option value='stef-nice'>Stef Nice</option>
						</Select>
					</FormControl>

					<FormControl style={{ width: '34%', marginLeft: 15 }}>
						<TextField
							label="Heure"
							type="time"
							defaultValue={shift.pickup_hour}
							onChange={(e) => {setPickupHour(index_day, index_shift, e.target.value);}}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								step: 300,
							}}
						/>
					</FormControl>
				</div>

				<div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 10 }}>

					{shops !== null &&
					<Autocomplete
						id={'search_' + key}
						value={input_value[index_shift]}
						onChange={(event, newValue) => {
							input_value[index_shift] = newValue;
							setInputValue({...input_value});
						}}
						options={Object.values(shops)}
						getOptionLabel={(option) => option.name}
						style={{ width: '80%'}}
						renderInput={(params) => <TextField InputLabelProps={{ shrink: true }} {...params} label="Ajouter un magasin" />}
					/>
					}

					<IconButton onClick={(e) => {addShop(index_day, index_shift)}} aria-label="add" size="small" style={{width : '20%', height : 30}} >
						<AddIcon fontSize="inherit"/>
					</IconButton>

				</div>

				{shops !== null &&
				<div style={{fontSize : 13, borderRadius : 10, border: '1px solid gray', padding : 2, height : '100%'}}>
					{shift.list.map((shop, i) => renderCard(shop, i, key, index_day, index_shift))}
				</div>
				}

				<Dustbin accept={key} lastDroppedItem={null} onDrop={(item) => handleDrop(index_shift, index_day, item)} key={'bin_' + key} day={index_day} shift={index_shift} />
			</div>
		);
	};


	const renderCard = (shop, index, key, day, shift) => {
		return (<Card key={key + '_' + shop.shop_id} index={index} uniq_key={key} id={shop.shop_id} day={day} shift={shift} shop={shop} text={shops[shop.shop_id].name} moveCard={moveCard}/>);
	};

    return (

    	<DndProvider backend={HTML5Backend}>

    		<Breadcrumbs aria-label="breadcrumb" style={{paddingBottom : 20}}>
				<Link to='/'>Accueil</Link>
				<Typography>Configuration</Typography>
			</Breadcrumbs>

			<h2 style={{paddingBottom : 40}}>Configuration</h2>

    		<div style={{display : 'flex', justifyContent : 'space-between', width : 600, marginBottom : 30}}>
    			{WeekDays.map((day, i) => (
				<div key={'week_days_' + i} style={selected_day === i ? {padding : 5, backgroundColor : '#000', color : '#FFF', borderRadius : 5} : {padding : 5, backgroundColor : '#F0F0F0', color : '#000', borderRadius : 5}} onClick={(e) => switchDay(i)}>
					{day}
				</div>
				))}
    		</div>

    		{(cards[selected_day] !== undefined) &&
    			renderDay(cards[selected_day], selected_day)
    		}

    		<Button variant="outlined" color="default" onClick={(e) => {save()}}>
				Enregistrer
			</Button>

		</DndProvider>
	);
}

export default React.memo(Conf);


const Card = ({ id, text, index, uniq_key, day, shift, moveCard, shop }) => {

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: uniq_key,
        hover(item, monitor) {

			if (!ref.current) {
			    return;
			}

			if (uniq_key !== item.uniq_key) return;

			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return;

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			    return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			    return;
			}

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex, uniq_key, day, shift);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: uniq_key, id, index, uniq_key, day, shift },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.1 : 1;
    drag(drop(ref));

    var backgroundColor = (shop.order_days !== undefined) ? '#ecedff' : 'white';
    var days = shop.order_days !== undefined ? shop.order_days : [];

	const style = {
		borderRadius : 8,
		border: '1px solid gray',
		padding: 3,
		marginBottom : 2,
		backgroundColor,
		cursor: 'move',
	};

    return (
		<div ref={ref} style={{ ...style, opacity }}>
			<div>
				{index + 1} {text}
			</div>
			<div>
			{days.map((d) => (
				<span key={uniq_key + '_' + d} style={{color : 'white', backgroundColor : '#6c79de', marginRight : 3, padding : 2, borderRadius : 3, fontSize : 8}}>{WeekDays[d].substr(0, 2)}</span>
			))}
			</div>
		</div>
	);
};



const Dustbin = ({ accept, lastDroppedItem, day, shift, onDrop }) => {

	const [{ isOver, canDrop }, drop] = useDrop({
		accept,
		drop: onDrop,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		})
	});

	const isActive = isOver && canDrop;
	let backgroundColor = 'grey';
	if (isActive) {
		backgroundColor = 'red';
	} else if (canDrop) {
		backgroundColor = 'grey';
	}

	var style = {
		borderRadius : 10,
		border: '1px solid gray',
		padding: 3,
	};

	return (
		<div ref={drop} style={{ ...style, backgroundColor, marginTop : 10, color : 'white', textAlign : 'center'}}>
			{isActive ? '🤢' : 'Supprimer'}
			{lastDroppedItem && (<p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>)}
		</div>
	);
};


