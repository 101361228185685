import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useState, useEffect } from 'react';
import { Typography, Breadcrumbs, Button} from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { UsersContext } from '../store/UsersProvider';
import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";

import SaleForm from './SaleForm';

function Sale(data) {

	const { shops, generateInvoice } = React.useContext(ShopsContext);
	const { user } = React.useContext(UsersContext);
	const { setOpenSnackBar, products_by_sku } = React.useContext(ShakiContext);

	var { shop_id, sale_id } = useParams();

	var history = useHistory();

	const [sale, setSale] = useState({});

	var action = (sale_id === undefined) ? 'create' :  'update';
	var title = (sale_id === undefined) ? 'Nouvelle Déclaration de vente' : 'Ventes du ' + sale.month + '/' + sale.year;


	useEffect(() => {

		return firebase.firestore().collection('sales_report').doc(sale_id).onSnapshot((doc) => {
			if (doc.exists === false) return;
			setSale(doc.data());
			reset(doc.data());
		});

	}, [sale_id]);


	const [invoice_generating, setInvoiceGenerating] = useState(false);
	useEffect(() => {

		if (sale_id === undefined) return;

		return firebase.firestore().collection('invoices_queue').doc(shop_id).onSnapshot((doc) => {
			setInvoiceGenerating(doc.exists);
		});

	}, [sale_id]);


	const [recipes, setRecipes] = useState({});
	useEffect(() => {

		if (Object.values(products_by_sku).length === 0) return;

		var new_recipes = {};
		for (var sku in products_by_sku) {

			if (products_by_sku[sku].disabled === true) continue;
			if (products_by_sku[sku].sub_recipe === true) continue;

			var brand = (products_by_sku[sku].brand !== undefined) ? products_by_sku[sku].brand : 'Autres';
			if (brand === '⚫️ Luna Retail') continue;
			if (new_recipes[brand] === undefined) new_recipes[brand] = {}

			var category = (products_by_sku[sku].category !== undefined) ? products_by_sku[sku].category : 'Autres';
			if (new_recipes[brand][category] === undefined) new_recipes[brand][category] = {}

			if (products_by_sku[sku].category !== undefined) {
				new_recipes[brand][products_by_sku[sku].category][sku] = products_by_sku[sku];
			} else {
				new_recipes[brand]['Autres'][sku] = products_by_sku[sku];
			}
		}

		setRecipes(new_recipes);

	}, [products_by_sku]);


	const { register, handleSubmit, reset, control } = useForm({
		defaultValues: sale
	});

	const onSubmit = (raw_data) => {

		var raw_data_stringified = JSON.stringify(raw_data);
		var data = JSON.parse(raw_data_stringified);

		for (var f in data) {
			if (data[f] === '') {
				delete data[f];
				continue;
			}
			data[f] = parseInt(data[f]);
		}

		data.shop_id = shop_id;
		data.id = shops[shop_id].key + '_' + data.month + '_' + data.year;

		if (action === 'create') {

			data.__meta = {
				creation_date: firebase.firestore.FieldValue.serverTimestamp(),
				created_by: user.id
			};

			firebase.firestore().collection('sales_report').doc(data.id).set(data).then(function() {
				setOpenSnackBar(true);
				history.push('/shops/' + shop_id + '/sale/' + data.id);
			}).catch(function(error) {
				console.error('Error adding shaki_orders: ', error);
			});

		} else {

			firebase.firestore().collection('sales_report').doc(data.id).update({
				'__meta.update_date': firebase.firestore.FieldValue.serverTimestamp(),
				'__meta.updated_by': user.id,
				...data
			}).then((doc) => {
				setOpenSnackBar(true);
			}).catch((error) => {
				console.error('Error updating sales_report: ', error);
			});
		}
	}


	const Brand = (row) => {
		return (
			<div style={{ backgroundColor: '#000', color: '#fff', padding: 5, paddingLeft: 10, marginTop: 20 }}>
				{row.category}
			</div>
		);
	}


	const Category = (row) => {
		return (
			<div style={{ borderBottom: '1px solid black', padding: 5, paddingLeft: 10, marginTop: 20 }}>
				{row.category}
			</div>
		);
	}

	var items = [];
	var sort_brand = Object.entries(recipes).sort();
 	for (var i in sort_brand) {

		items.push(<Brand key={String(i)} category={sort_brand[i][0]} />);

		var sort_category = Object.entries(sort_brand[i][1]).sort();
		for (var j in sort_category) {

			items.push(<Category key={String(i) + '_' + String(j)} category={sort_category[j][0]} />);

			var sort_sku = Object.entries(sort_category[j][1]).sort();
			for (var k in sort_sku) {
				items.push(<SaleForm key={String(i) + '_' + String(j) + '_' + String(k)} register={register} control={control} {...sort_sku[k][1]} />);
			}
		}
	}


	if (shops === null) return null;


	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{paddingBottom : 20}}>
				<Link to='/'>Accueil</Link>
				<Link to={'/shops/' + shop_id}>{shops[shop_id].name}</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ paddingBottom : 40 }}>{title}</h2>

			<div>
				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>

						<FormControl style={{}}>
							<InputLabel htmlFor={'month'} shrink>Mois</InputLabel>
							<Select
								inputRef={register()}
								native
								name={'month'}
								id={'month'}
							>
								<option value='1'>Janvier</option>
								<option value='2'>Février</option>
								<option value='3'>Mars</option>
								<option value='4'>Avril</option>
								<option value='5'>Mai</option>
								<option value='6'>Juin</option>
								<option value='7'>Juillet</option>
								<option value='8'>Aout</option>
								<option value='9'>Septembre</option>
								<option value='10'>Octobre</option>
								<option value='11'>Novembre</option>
								<option value='12'>Décembre</option>
							</Select>
						</FormControl>

						<FormControl style={{ marginLeft: 10 }}>
							<InputLabel htmlFor={'year'} shrink>Année</InputLabel>
							<Select
								inputRef={register()}
								native
								name={'year'}
								id={'year'}
							>
								<option value='2022'>2022</option>
								<option value='2021'>2021</option>
							</Select>
						</FormControl>

						{action === 'update' &&
							<Button style={{ marginLeft: 10 }} disabled={invoice_generating} onClick={(e) => {generateInvoice('invoice_revshare', shop_id, sale.year, sale.month); return true;}} variant='contained' color='primary' size='small'>
								Générer la facture du mois
							</Button>
						}

					</div>

					{items}

					<div>
						<Button variant="contained" color="primary" type='submit'>
							Enregistrer
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default React.memo(Sale);

