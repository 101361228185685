import React from 'react';
import { useState, useContext } from 'react';
import {BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import { ListItemText, ListItemIcon, ListItem, Drawer, Menu, MenuItem, IconButton, Divider, InputBase, Typography, List, Toolbar, AppBar, Avatar, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {  ChevronRight, ChevronLeft} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';

import MenuIcon from '@material-ui/icons/Menu';
//import MailIcon from '@material-ui/icons/Mail';
//import InboxIcon from '@material-ui/icons/Inbox';
//import CloseIcon from '@material-ui/icons/Close';

import Home from './components/Home';
import Orders from './components/Orders';
import Order from './components/Order';
import Production from './components/Production';
import Invoices from './components/Invoices';
import LunaMenu from './components/LunaMenu';


import LunaAvatars from './components/LunaAvatars';
import LunaAvatar from './components/LunaAvatar';
import LunaProducts from './components/LunaProducts';
import LunaProduct from './components/LunaProduct';

import Shops from './components/Shops';
import Shop from './components/Shop';

import Products from './components/Products';
import Product from './components/Product';
import ProductionLines from './components/ProductionLines';

import Users from './components/Users';
import User from './components/User';
import Sale from './components/Sale';
import CreateOrder from './components/CreateOrder';
import Prices from './components/Prices';
import Price from './components/Price';

import Planograms from './components/Planograms';
import Planogram from './components/Planogram';

import Conf from './components/Conf';
import Tours from './components/Tours';

import Stats from './components/Stats';

import { SignIn } from './components/SignIn';
import { Index } from './components/Index';

import { UsersContext } from './store/UsersProvider';
import { ShakiContext } from './store/ShakiProvider';

import { GetImagePath } from './Utils';


import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';

import './App.css';
import luna_logo from './img/luna.svg';

function Nav() {

	const { onSearch, open_snack_bar, setOpenSnackBar, app_bar_visibility } = useContext(ShakiContext);
	const { user, user_status, logout, checkUserRights } = useContext(UsersContext);

	const classes = useStyles();
	const theme = useTheme();

	const [open, setOpen] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const open1 = Boolean(anchorEl);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	if (user === null && user_status === 1) {
		return (
			<div>
				ca arrive !!!
			</div>
		);
	}

	return (
		<>
		{(user !== null ?
			<Router>
			<div className={classes.root}>
			<AppBar
				style={{ display: app_bar_visibility ? 'block' : 'none' }}
				position="fixed"
				color='default'
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<MenuIcon />
					</IconButton>

					<Typography variant="h6" noWrap className={classes.title}>
						<img src={luna_logo} style={{ width : 80 }} alt=''/>
					</Typography>

					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							placeholder="Search…"
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput,
							}}
							inputProps={{ 'aria-label': 'search' }}
							onChange={onSearch}
						/>
					</div>

					<div>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<Avatar src={GetImagePath(user.images, 'main')} style={{ width: 30, height: 30 }}>{user.first_name !== undefined ? user.first_name.substr(0, 1) : 'A'}</Avatar>
						</IconButton>

						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={open1}
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose}>{user.first_name} {user.last_name}</MenuItem>
							<MenuItem onClick={logout}>Se déconnecter</MenuItem>
						</Menu>
					</div>

				</Toolbar>
			</AppBar>

			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
				style={{ display: app_bar_visibility ? 'block' : 'none' }}
			>
				<div className={classes.drawerHeader}>
					{open === 'lala' &&
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
					</IconButton>
					}
				</div>

				<Divider />
				<List>
					<ListItem button component={Link} to="/">
						<ListItemText primary='Accueil' />
					</ListItem>

					<Divider />

					{false &&
					<ListItem button component={Link} to="/orders">
						<ListItemIcon className={classes.icon}><ListOutlinedIcon /></ListItemIcon>
						<ListItemText primary='Mes commandes'/>
					</ListItem>
					}

					{false &&
					<ListItem button component={Link} to="/neworder">
						<ListItemIcon className={classes.icon}><AddCircleOutlineOutlinedIcon /></ListItemIcon>
						<ListItemText primary='Nouvelle commande'/>
					</ListItem>
					}

					{false &&
					<ListItem button component={Link} to="/shops/me">
						<ListItemIcon className={classes.icon}><AssignmentIndOutlinedIcon /></ListItemIcon>
						<ListItemText primary='Profil'/>
					</ListItem>
					}

					{false &&
					<Divider />
					}

					{checkUserRights('production') &&
					<ListItem button component={Link} to="/production">
						<ListItemText primary='Production'/>
					</ListItem>
					}

					{checkUserRights('production') &&
					<ListItem button component={Link} to="/orders/all">
						<ListItemText primary='Commandes de la semaine'/>
					</ListItem>
					}

					{checkUserRights('invoices') &&
					<ListItem button component={Link} to="/invoices/all">
						<ListItemText primary='Factures'/>
					</ListItem>
					}

					{checkUserRights('shops') &&
					<ListItem button component={Link} to="/shops">
						<ListItemText primary='Clients'/>
					</ListItem>
					}

					{checkUserRights('stats') &&
					<ListItem button component={Link} to="/stats">
						<ListItemText primary='Statistiques'/>
					</ListItem>
					}

					{checkUserRights('pricelists') &&
					<ListItem button component={Link} to="/prices">
						<ListItemText primary='Liste de prix'/>
					</ListItem>
					}

					{checkUserRights('products') &&
					<ListItem button component={Link} to="/products">
						<ListItemText primary='Produits'/>
					</ListItem>
					}

					{checkUserRights('production') &&
					<ListItem button component={Link} to="/production_lines">
						<ListItemText primary='Lignes de production'/>
					</ListItem>
					}

					{checkUserRights('retail') &&
					<>
						<Divider />

						{checkUserRights('retail_products') &&
						<ListItem button component={Link} to="/luna_products">
							<ListItemText primary='Produits Luna'/>
						</ListItem>
						}


						<ListItem button component={Link} to="/luna_avatars">
							<ListItemText primary='Avatars Luna'/>
						</ListItem>
					</>
					}

					<Divider />

					{checkUserRights('users') &&
					<ListItem button component={Link} to="/users">
						<ListItemText primary='Utilisateurs'/>
					</ListItem>
					}


					{checkUserRights('configuration') &&
					<ListItem button component={Link} to="/conf">
						<ListItemText primary='Configuration'/>
					</ListItem>
					}

					{checkUserRights('configuration') &&
					<ListItem button component={Link} to="/tours">
						<ListItemText primary='Tournées'/>
					</ListItem>
					}

				</List>
				<Divider />
			</Drawer>

			<main className={clsx(classes.content, {
				[classes.contentShift]: open,
				[classes.contentFullScreen]: !app_bar_visibility,
			})}>

				{app_bar_visibility &&
					<div className={classes.drawerHeader} />
				}

				<div>
					<Switch>

						{/*user logged*/}

						<Route path='/neworder'>
							<CreateOrder />
						</Route>
						<Route exact path='/order/:order_id/edit'>
							<CreateOrder />
						</Route>
						<Route path='/order/:id'>
							<Order />
						</Route>

						<Route exact path='/production'>
							<Production />
						</Route>
						<Route exact path='/orders'>
							<Orders />
						</Route>

						{/*admin logged*/}

						<Route exact path='/invoices/:shop_id'>
							<Invoices />
						</Route>

						<Route exact path='/orders/:shop_id'>
							<Orders />
						</Route>

						<Route path='/shops/:shop_id/planograms/:planogram_id'>
							<Planogram />
						</Route>
						<Route path='/shops/:shop_id/planograms'>
							<Planograms />
						</Route>


						<Route path='/shops/:shop_id/sale/:sale_id'>
							<Sale />
						</Route>
						<Route path='/shops/:shop_id/newsale'>
							<Sale />
						</Route>
						<Route path='/shops/:shop_id/neworder'>
							<CreateOrder />
						</Route>
						<Route path='/shops/:shop_id'>
							<Shop />
						</Route>
						<Route path='/shops'>
							<Shops />
						</Route>

						<Route path='/users/:id'>
							<User />
						</Route>
						<Route path='/users'>
							<Users />
						</Route>

						<Route path='/products/:product_id'>
							<Product />
						</Route>

						<Route path='/products'>
							<Products />
						</Route>

						<Route path='/production_lines'>
							<ProductionLines />
						</Route>

						<Route path='/luna_products/:product_id'>
							<LunaProduct />
						</Route>
						<Route path='/luna_products'>
							<LunaProducts />
						</Route>
						<Route path='/luna_menu/:shop_id'>
							<LunaMenu />
						</Route>


						<Route path='/luna_avatars/:avatar_id'>
							<LunaAvatar />
						</Route>
						<Route path='/luna_avatars'>
							<LunaAvatars />
						</Route>

						<Route path='/prices/:id'>
							<Price />
						</Route>
						<Route path='/prices'>
							<Prices />
						</Route>

						<Route path='/conf'>
							<Conf />
						</Route>

						<Route path='/tours'>
							<Tours />
						</Route>

						<Route path='/stats'>
							<Stats />
						</Route>

						<Route path='/'>
							<Home />
						</Route>

					</Switch>
				</div>
			</main>
			</div>
			</Router>
		:
			<Router>
				<div>
					<Switch>

						<Route path='/luna_menu/:shop_id'>
							<LunaMenu />
						</Route>

						<Route path='/'>
							<SignIn />
						</Route>

					</Switch>
				</div>
			</Router>
		)}

		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={open_snack_bar}
			autoHideDuration={2000}
			onClose={() => setOpenSnackBar(false)}
		>
			<MuiAlert elevation={6} variant="filled" onClose={() => { setOpenSnackBar(false) }} severity="success">
				Sauvegardé
			</MuiAlert>
		</Snackbar>
		</>
	);
}

export default React.memo(Nav);


const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
	icon: {
		minWidth: '30px',
	},
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	contentFullScreen: {
		padding: 0,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));

