import React, { useContext, useEffect, useState } from 'react';
import { UsersContext } from '../store/UsersProvider';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import { activate, fetchAndActivate, fetchConfig, getRemoteConfig, getValue } from "firebase/remote-config";
import { getApp } from 'firebase/app';

export const ShakiContext = React.createContext({});

function ShakiProvider({ children }) {

	const { user, checkUserRights } = useContext(UsersContext);

	const [routes_config, setRoutesConfig] = useState({});
	const [products, setProducts] = useState({});
	const [products_by_sku, setProductsBySku] = useState({});
	const [containers, setContainers ] = useState({});
	const [remote_config, setRemoteConfig] = useState({});
	const [remote_config_fetched, setRemoteConfigFetch] = useState(false);
	const [tags, setTags] = useState([]);

	var remoteConfig = getRemoteConfig(getApp());


	/*
	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('remote_config').doc('M6bLdS72ew4eBvP4kNP5').onSnapshot((doc) => {

			console.log('refresh remote_config', doc.data())

			setRemoteConfig(doc.data());
		});

	}, [user]);
	*/


	useEffect(async () => {

		remoteConfig.settings.minimumFetchIntervalMillis = 5 * 60 * 1000;

		fetchAndActivate(remoteConfig).then((fetchedRemotely) => {

			if (fetchedRemotely) {
				console.log('Configs were retrieved from the backend and activated.');
			} else {
				console.log('No configs were fetched from the backend, and the local configs were already activated',);
			};

			setRemoteConfigFetch(true);

		});

	}, []);


	const getRemoteConf = (key) => {

		var value = getValue(remoteConfig, key);
		console.log('getRemoteConfig', key, value)

		if (isNaN(parseInt(value.asString())) === false) {
			return value.asNumber();
		}

		if (value.asString().substr(0, 1) === '{' || value.asString().substr(0, 1) === '[') {
			return JSON.parse(value.asString());
		}

		if (value.asString() === 'true' || value.asString() === 'false') {
			return value.asBoolean();
		}

		return value.asString();
	};


	useEffect(() => {

		if (remote_config_fetched === false) return;

		var t = getRemoteConf('tags');
		var h = {};
		for (var row of t) {
			h[row.key] = row;
		};
		setTags(h);

	}, [remote_config_fetched]);



	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_products').onSnapshot((querySnapshot) => {

			if (querySnapshot === null) return;

			var hash = {};
			var sku_hash = {};
			querySnapshot.forEach(doc => {

				var data = doc.data();
				data.id = doc.id;
				data.search_index = data.sku.toLowerCase() + ' ';
				if (data.name !== undefined) data.search_index += data.name.toLowerCase() + ' ';
				if (data.brand !== undefined) data.search_index += data.brand.toLowerCase() + ' ';
				if (data.category !== undefined) data.search_index += data.category.toLowerCase() + ' ';

				hash[doc.id] = data;
				sku_hash[data.sku] = data;
			});

			setProducts(hash);
			setProductsBySku(sku_hash);
		});

		//return () => subscriber();

	}, [user]);


	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes_config').where('day', '>', 0).onSnapshot((querySnapshot) => {

			var hash = {};
			querySnapshot.forEach(doc => {
				hash[doc.id] = doc.data();
			});

			setRoutesConfig(hash);
		});

		//return () => subscriber();

	}, [user]);


	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_containers').onSnapshot((querySnapshot) => {

			if (querySnapshot === null) return;

			const list_hash = {};
			querySnapshot.forEach(doc => {
				list_hash[doc.id] = doc.data();
			});

			setContainers(list_hash);
		});

		//return () => subscriber();

	}, [user]);


	// Production lines order
	const getProductionLinesConf = (callback) => {
		return firebase.firestore().collection('remote_config').doc('production_lines').get().then((doc) => {
			callback(doc.data());
		}).catch((error) => {
			console.error('Error Get Luna Product', error);
		});
	};


	const setProductionLinesConf = (data, callback = null) => {
		return firebase.firestore().collection('remote_config').doc('production_lines').set(data).then(() => {
			if (callback !== null) callback(true);
		}).catch((error) => {
			console.error('Error Get Luna Product', error);
		});
	};


	const GetProduct = (id, callback) => {
		return firebase.firestore().collection('shaki_products').doc(id).onSnapshot(callback);
	}


	const GetLunaProducts = (callback) => {
		return firebase.firestore().collection('luna_products').onSnapshot(callback);
	}


	const GetLunaProduct = (id, callback, snapshot = false) => {
		if (snapshot) {
			return firebase.firestore().collection('luna_products').doc(id).onSnapshot(callback);
		} else {
			return firebase.firestore().collection('luna_products').doc(id).get().then((doc) => {
				callback(doc);
			}).catch((error) => {
				console.error('Error Get Luna Product', error);
			});
		}
	}


	const CreateLunaProduct = (data, callback = null) => {

		console.log('Create Luna Product', data, user);

		if (!checkUserRights()) return;

		data.__meta = {
			creation_date: firebase.firestore.FieldValue.serverTimestamp(),
			created_by: user.uid
		}

		var doc = firebase.firestore().collection('luna_products').doc();
		data.id = doc.id;

		doc.set(data).then(() => {
			if (callback !== null) callback(doc.id);
		}).catch((error) => {
			console.error('Error Create Luna Product: ', error);
		});
	}


	const UpdateLunaProduct = (id, data, callback = null) => {

		console.log('Update Luna Product', data, user);

		if (!checkUserRights()) return;

		data.__meta = {
			last_update_date: firebase.firestore.FieldValue.serverTimestamp(),
			updated_by: user.uid
		}

		var doc = firebase.firestore().collection('luna_products').doc(id).update(data).then(() => {
			if (callback !== null) callback(doc.id);
		}).catch((error) => {
			console.error('Error Update Luna Product: ', error);
		});;

	}


	const GetLunaAvatars = (callback) => {
		return firebase.firestore().collection('luna_avatars').onSnapshot(callback);
	}


	const GetLunaAvatar = (id, callback, snapshot = false) => {
		if (snapshot) {
			return firebase.firestore().collection('luna_avatars').doc(id).onSnapshot(callback);
		} else {
			return firebase.firestore().collection('luna_avatars').doc(id).get().then((doc) => {
				callback(doc);
			}).catch((error) => {
				console.error('Error Get Luna Avatar', error);
			});
		}
	}


	const CreateLunaAvatar = (data, callback = null) => {

		console.log('Create Luna Avatar', data, user);
		if (!checkUserRights()) return;

		data.__meta = {
			creation_date: firebase.firestore.FieldValue.serverTimestamp(),
			created_by: user.uid
		}

		var doc = firebase.firestore().collection('luna_avatars').doc();
		data.id = doc.id;

		doc.set(data).then(() => {
			if (callback !== null) callback(doc.id);
		}).catch((error) => {
			console.error('Error Create Luna Avatar: ', error);
		});
	}


	const UpdateLunaAvatar = (id, data, callback = null) => {

		console.log('Update Luna Avatar', data, user);
		if (!checkUserRights()) return;

		data.__meta = {
			last_update_date: firebase.firestore.FieldValue.serverTimestamp(),
			updated_by: user.uid
		}

		var doc = firebase.firestore().collection('luna_avatars').doc(id).update(data).then(() => {
			if (callback !== null) callback(doc.id);
		}).catch((error) => {
			console.error('Error Update Luna Avatar: ', error);
		});;
	}



	const [open_snack_bar, setOpenSnackBar] = useState(false);
	const [search, setSearch] = useState('');
	const onSearch = e => {
		setSearch(e.target.value.toLowerCase())
	}

	const [app_bar_visibility, setAppBarVisibility] = useState('block');

	return(
		<ShakiContext.Provider value={{

			remote_config: remote_config,
			tags: tags,

			search : search,
			onSearch: onSearch,
			open_snack_bar: open_snack_bar,
			setOpenSnackBar: setOpenSnackBar,
			app_bar_visibility: app_bar_visibility,
			setAppBarVisibility: setAppBarVisibility,

			containers: containers,
			GetProduct: GetProduct,
			products_by_sku: products_by_sku,
			products: products,
			routes_config: routes_config,
			getRemoteConf: getRemoteConf,

			CreateLunaProduct: CreateLunaProduct,
			UpdateLunaProduct: UpdateLunaProduct,
			GetLunaProduct: GetLunaProduct,
			GetLunaProducts: GetLunaProducts,

			GetLunaAvatars: GetLunaAvatars,
			GetLunaAvatar: GetLunaAvatar,
			CreateLunaAvatar: CreateLunaAvatar,
			UpdateLunaAvatar: UpdateLunaAvatar,

			getProductionLinesConf: getProductionLinesConf,
			setProductionLinesConf: setProductionLinesConf
		}}>
			{children}
		</ShakiContext.Provider>
	);
}

export default React.memo(ShakiProvider);
