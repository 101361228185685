import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar, IconButton } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { UsersContext } from '../store/UsersProvider';
import { OrdersContext } from '../store/OrdersProvider';
import { ShopsContext } from "../store/ShopsProvider";
import { ShakiContext } from "../store/ShakiProvider";

import { GetPrice, GetTotalPrices, GetTotalWeight, GetImagePath, DownloadFile } from '.././Utils';
import { DateTime } from "luxon";

import OrderCredit from './OrderCredit';


function Order() {

	const { users_hash } = useContext(UsersContext);
	const { products_by_sku, containers, routes_config, getRemoteConf } = useContext(ShakiContext);
	const { GetOrder, GetOrderRoutes, startRoute, completeRoute, saveDelivered, generateInvoice, generateDeliveryFile } = useContext(OrdersContext)
	const { GetShop, shops, price_list, GetCurrentRateCard } = useContext(ShopsContext);

	const [dialog_credit_opened, setDialogCrediOpened] = useState(false);
	const [dialog_credit_content, setDialogCreditContent] = useState({});

	const [delivery_operators, setDeliveryOperators] = useState([]);

	var { id } = useParams();

	var history = useHistory();
	const classes = useStyles();
	const obsolete = true;

	const [containers_list, setContainersList] = useState([]);
	const [order, setOrder] = useState(null);
	const [delivered, setDelivered] = useState({})
	const [order_routes, setOrderRoutes] = useState([]);
	const [shop, setShop] = useState(null);

	const [sort, setSort] = useState('asc');
	const [orderBy, setOrderBy] = useState('sku');

	const [open_gallery, setOpenGallery] = useState(false);
	const [image_gallery, setImageGallery] = useState(null);
	const [invoice_generating, setInvoiceGenerating] = useState(false);


	useEffect(() => {
		setDeliveryOperators(getRemoteConf('delivery_operators'));
	}, []);


	useEffect(() => {

		if (shops === null) return;
		if (Object.values(price_list).length === 0) return;

		GetOrder(id, (data) => {
			setOrder(data);
			setShop(data.shop);
			setDelivered(data.delivered !== undefined ? {...data.delivered} : {...data.products});
		});

	}, [shops, price_list]);


	useEffect(() => {

		if (order === null) return;

		GetOrderRoutes(order.id, (list) => {
			setOrderRoutes(list);
		});

	}, [order]);


	useEffect(() => {

		return firebase.firestore().collection('invoices_queue').doc(id).onSnapshot((doc) => {
			setInvoiceGenerating(doc.exists);
		});

	}, []);


	useEffect(() => {

		if (Object.values(containers).length === 0) return;
		if (order === null) return;

		var list = [];
		if (order.containers !== undefined) {
			for (var i in order.containers) {
				if (order.container_type === 'cagette_plastic') {
					list.push(containers[order.containers[i]].name);
				} else {
					list.push(order.containers[i]);
				}
			}
		}

		setContainersList(list)

	}, [containers, order]);


	if (order === null) return null;
	if (shop === null) return null;


	/*
	const countProduct = list => {
		var count = 0;
		for (var i in list) {
			count += list[i].amount;
		}
		return count;
	};
	*/


	// Table
	const headCells = [
		{id : 'sku', numeric : false, disablePadding : false, label : 'SKU'},
		{id : 'product_name', numeric : false, disablePadding : false, label : 'Produit'},
		{id : 'amount', numeric : true, disablePadding : false, label : 'Quantité'}
	];


	const createSortHandler = (property) => (event) => {
		//onRequestSort(event, property);
		//console.log(property);
		const isAsc = orderBy === property && sort === 'asc';
		setSort(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};


	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}


	function getComparator(order, orderBy) {
		return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
	}


	function stableSort(array, comparator) {

		array.sort((a, b) => {
			const order = comparator(a, b);
			if (order !== 0) {
				return order;
			}
			return a - b;
		});

		return array;
	}


	function incr(sku, incr) {

		if (delivered[sku] === undefined) delivered[sku] = 0;
		delivered[sku] += incr;

		setDelivered({...delivered});
	}


	const productList = (products) => {

		var ret = [];
		for (var sku in products) {
			ret.push({amount: products[sku], ...products_by_sku[sku]})
		}

		return ret;
	}


	const handleClickOpen = () => {
		setDialogCrediOpened(true);
	};


	const handleClose = (value) => {
		setDialogCrediOpened(false);
	};

console.log(order.__meta)
	return (
		<div>

			<OrderCredit
				order_id={id}
				open={dialog_credit_opened}
				onClose={handleClose}
			/>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom : 20 }}>
				<Link to='/'>Accueil</Link>
				<Link to={'/shops/' + order.shop_id}>{shop.name}</Link>
				<Typography>{order.key}</Typography>
			</Breadcrumbs>

			<h2 style={{ paddingBottom : 40 }}>Commande {order.key}</h2>

			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>

				<div>

					<Typography>Commande crée le <b>{DateTime.fromSeconds(order.__meta.creation_date.seconds).setLocale('fr').toFormat('ccc dd LLL yyyy à hh:mm')}</b> {(order.__meta.created_by !== undefined && order.__meta.created_by !== 'admin') ? ('par ' + users_hash[order.__meta.created_by].first_name) : ''}</Typography>

					{order.script_ratio_waterfall !== undefined &&
						<>
						<span>Ratios appliqués : </span>

						{order.script_ratio_waterfall.map((row, index) => (
							<span style={{ fontWeight: '500', color: row.value !== 1 ? 'green' : 'black' }}>{row.label} : <b>{row.value}</b> {row.label.search('Global') === -1 ? <span style={{ color: 'black' }}>|</span> : ""} </span>
						))}
						</>
					}

					<Typography>Commande en production le <b>{DateTime.fromISO(order.day_prod).setLocale('fr').toFormat('ccc dd LLL yyyy')}</b></Typography>

					<Typography>Commande attendue le <b>{DateTime.fromISO(order.day).setLocale('fr').toFormat('ccc dd LLL yyyy')}</b> chez {shop.name}</Typography>

					{order.prepared_at !== undefined &&
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{order.container_type === 'cagette_plastic' &&
								<Typography>Commande préparée le <b>{DateTime.fromSeconds(order.prepared_at).setLocale('fr').toFormat('ccc dd LLL yyyy')}</b> | {containers_list.length} cagette(s): </Typography>
							}
							{order.container_type === 'cagette_carton' &&
								<Typography>Commande préparée le <b>{DateTime.fromSeconds(order.prepared_at).setLocale('fr').toFormat('ccc dd LLL yyyy')}</b> | {containers_list.length} carton(s)</Typography>
							}
							{order.container_type === 'pallet' &&
								<Typography>Commande préparée le <b>{DateTime.fromSeconds(order.prepared_at).setLocale('fr').toFormat('ccc dd LLL yyyy')}</b> | {containers_list.length} palette(s): </Typography>
							}
							{containers_list.map((row) => (
								<div className={classes.container}>{row}</div>
							))}
						</div>
					}
				</div>

			</div>

			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingBottom: 10 }}>

				{parseInt(DateTime.local().toFormat('yyyyLLdd')) < parseInt(order.day_prod) &&
					<Button onClick={(e) => history.push('/order/' + id + '/edit')} variant='contained' color="primary" size="small">
						Editer la commande
					</Button>
				}

				{(!obsolete && parseInt(DateTime.local().toFormat('yyyyLLdd')) > parseInt(order.day_prod) && shop.invoicing.type === 'invoice_order') &&
					<Button disabled={invoice_generating} onClick={(e) => {generateInvoice(order.id); return true;}} variant='contained' color='primary' size="small" style={{ marginLeft: 10, marginRight: 8 }}>
						Générer la facture
					</Button>
				}

				<Button disabled={(order.credited !== undefined && Object.values(order.credited).length > 0)} onClick={(e) => {setDialogCrediOpened(true); return true;}} variant='contained' color='primary' size="small" style={{ marginLeft: 10 }}>
					Générer un avoir
				</Button>

			</div>

			<div style={{ marginBottom: 40 }}>

				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="customized table">
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<StyledTableCell
										key={headCell.id}
										align={headCell.numeric ? 'right' : 'left'}
										padding={headCell.disablePadding ? 'none' : 'default'}
										sortDirection={orderBy === headCell.id ? sort : false}
									>
										<StyledTableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? sort : 'asc'}
											onClick={createSortHandler(headCell.id)}
										>
											<b>{headCell.label}</b>
											{orderBy === headCell.id ? (
												<span className={classes.visuallyHidden}>
													{sort === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</StyledTableSortLabel>
									</StyledTableCell>
								))}
								<StyledTableCell align="right"><b>Préparé par</b></StyledTableCell>
								<StyledTableCell align="right"><b>Crédité</b></StyledTableCell>
								<StyledTableCell align="right"><b>PU</b></StyledTableCell>
								<StyledTableCell align="right"><b>Total HT</b></StyledTableCell>
								<StyledTableCell align="right"><b>Total Delivré HT</b></StyledTableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{stableSort(productList(order.products), getComparator(sort, orderBy)).map((row) => (
							<StyledTableRow key={row.sku}>
								<StyledTableCell component="th" scope="row">{row.sku}</StyledTableCell>
								<StyledTableCell>{row.name}</StyledTableCell>
								<StyledTableCell align="right">

									{(delivered[row.sku] !== undefined && delivered[row.sku] > 0) &&
										<IconButton size="small" onClick={() => incr(row.sku, -1)}>
											<RemoveIcon fontSize="inherit"/>
										</IconButton>
									}

									{row.amount} {(delivered[row.sku] !== undefined && ((delivered[row.sku] - row.amount) !== 0)) ? '(' + (delivered[row.sku] - row.amount) + ')' : ''}

									<IconButton size="small" onClick={() => incr(row.sku, 1)}>
										<AddIcon fontSize="inherit"/>
									</IconButton>

								</StyledTableCell>
								<StyledTableCell align="right">{(order.prepared !== undefined && order.prepared[row.sku] !== undefined && order.prepared[row.sku].by !== '') ? users_hash[order.prepared[row.sku].by].first_name + ' - ' + order.prepared[row.sku].amount : ''}</StyledTableCell>
								<StyledTableCell align="right">{(order.credited !== undefined && order.credited[row.sku] !== undefined) ? order.credited[row.sku] : '-'}</StyledTableCell>
								<StyledTableCell align="right">{GetPrice(order.rate_card, order.pricelist, null, row.sku, 1, true)}</StyledTableCell>
								<StyledTableCell align="right">{GetPrice(order.rate_card, order.pricelist, null, row.sku, order.products[row.sku], true)}</StyledTableCell>
								<StyledTableCell align="right">{GetPrice(order.rate_card, order.pricelist, null, row.sku, delivered[row.sku], true)}</StyledTableCell>
							</StyledTableRow>
							))}
						</TableBody>
					</Table>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent : 'space-between', padding: 16, color: 'white', backgroundColor: 'darkslateblue' }}>
						<div>
							<Button disabled={JSON.stringify(order.delivered) === JSON.stringify(delivered)} onClick={(e) => {saveDelivered(order.id, delivered); return true;}} variant='contained' color='primary' size="small">
								Sauvegarder
							</Button>
						</div>
						<div>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent : 'space-between' }}>
								<div style={{ marginRight: 20 }}>Commandé</div>
								<div><b>Poids</b>&nbsp;&nbsp;{GetTotalWeight(order.products)}kg | <b>Total</b>&nbsp;&nbsp;{GetTotalPrices(order.rate_card, order.pricelist, order.products)}</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent : 'space-between' }}>
								<div style={{ marginRight: 20 }}>Livré</div>
								<div><b>Poids</b>&nbsp;&nbsp;{GetTotalWeight(delivered)}kg | <b>Total</b>&nbsp;&nbsp;{GetTotalPrices(order.rate_card, order.pricelist, delivered)}</div>
							</div>
						</div>
					</div>
				</TableContainer>

			</div>

			<div>

				<div style={{ display: 'flex', width: '45%' }}>
					{(order_routes.length > 0 && order_routes[order_routes.length - 1].worker !== undefined) &&
						<div style={{ display: 'flex', alignItems: 'flex-end' }}>
							<Typography>{'Livrée par '}</Typography>
							{users_hash[order_routes[order_routes.length - 1].worker].picture !== undefined &&
							<Avatar
 								src={GetImagePath(users_hash[order_routes[order_routes.length - 1].worker].images, 'main')}
								style={{ width: 25, height: 25, marginRight: 5, marginLeft: 5 }}
							/>
							}
							<Typography><b>{users_hash[order_routes[order_routes.length - 1].worker].first_name}</b></Typography>
						</div>
					}
				</div>

				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="customized table">

						<TableHead>
							<TableRow>
								<StyledTableCell align="right"></StyledTableCell>
								<StyledTableCell><b>Type</b></StyledTableCell>
								<StyledTableCell><b>Destination</b></StyledTableCell>
								<StyledTableCell><b>Date</b></StyledTableCell>
								<StyledTableCell><b>Statut</b></StyledTableCell>
							</TableRow>
						</TableHead>

						<TableBody>
						{order_routes.map((row, index) => (

							<>
							<StyledTableRow key={row.id} style={{textDecoration: (row.state === 2 ? 'line-through' : 'none')}}>
								<StyledTableCell align="right">{row.step + 1}</StyledTableCell>
								<StyledTableCell>{row.type} ({routes_config[row.tour] !== undefined ? routes_config[row.tour].name : row.tour } par {delivery_operators[row.operator].name})</StyledTableCell>
								<StyledTableCell>{row.address.name}</StyledTableCell>
								<StyledTableCell>{DateTime.fromISO(row.date).toFormat('dd/LL/yyyy')}</StyledTableCell>

								{row.state === 0 &&
									<StyledTableCell>
										<Button onClick={(e) => startRoute(row.id)} variant='contained' color='primary' size="small">
											Démarrer
										</Button>
									</StyledTableCell>
								}

								{row.state === 1 &&
									<StyledTableCell>
										<Button onClick={(e) => completeRoute(row.id)} variant='contained' color='primary' size="small">
											Finir
										</Button>
									</StyledTableCell>
								}

								{row.state === 2 &&
									<StyledTableCell>
										Terminé à {DateTime.fromMillis(row.completed_at.time * 1000).toFormat('HH:mm')}
										<Button style={{ marginLeft: 10 }} onClick={(e) => startRoute(row.id)} variant='contained' color='primary' size="small">
											Redémarrer
										</Button>

										{(order.delivery_file === undefined && row.type === 'dropoff') &&
											<Button style={{ marginLeft: 10 }} onClick={(e) => generateDeliveryFile(row.id, order.id)} variant='contained' color='primary' size="small">
												Générer et envoyer le bon de livraison
											</Button>
										}
									</StyledTableCell>
								}

							</StyledTableRow>

							{row.images !== undefined && Object.values(row.images).length > 0 &&
								<TableRow>
									<StyledTableCell></StyledTableCell>
									<StyledTableCell colSpan={4}>
										{Object.entries(row.images).map((image, index) => (
											<img
											key={image[0].path}
											onClick={() => {setImageGallery(GetImagePath(row.images, image[0], 'origin')); setOpenGallery(true); }} style={{ borderRadius: 10, marginRight: 20, border: '1px solid grey' }}
											width='100'
											src={GetImagePath(row.images, image[0])}
											/>
										))}
									</StyledTableCell>
								</TableRow>
							}

							{(row.completion_details !== undefined && row.completion_details.notes !== '') &&
								<TableRow>
									<StyledTableCell></StyledTableCell>
									<StyledTableCell colSpan={4}>
										Notes: {row.completion_details.notes}
									</StyledTableCell>
								</TableRow>
							}

							</>
						))}

						</TableBody>
					</Table>

					<div style={{ display: 'flex', flexDirection: 'row', justifyContent : 'end', padding: 16, color: 'white', backgroundColor: 'darkslateblue' }}>

						{order.pre_delivery_file !== undefined &&
							<Button style={{ marginRight: 10 }} onClick={(e) => DownloadFile(order.pre_delivery_file)} variant='contained' color='primary' size="small">
								Télécharger le pré bon de livraison
							</Button>
						}

						{order.delivery_file !== undefined &&
							<Button onClick={(e) => DownloadFile(order.delivery_file)} variant='contained' color='primary' size="small">
								Télécharger le bon de livraison
							</Button>
						}

					</div>

				</TableContainer>

				<Dialog open={open_gallery} onClose={() => { setOpenGallery(false);}}>
					<img alt='' width='450' src={image_gallery} />
				</Dialog>

			</div>
		</div>
	);
}

export default React.memo(Order);

const StyledTableCell = withStyles((theme) => ({
	head : {
		//backgroundColor : '#000',
		//color : theme.palette.common.white,
	},
	body : {
		//fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
	root : {
		fontWeight : '500',
	},
	iconDirectionDesc : {},
	iconDirectionAsc : {}
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
		//	backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		//minWidth: 700,
	},
	container : {
		backgroundColor : '#000',
		color : '#FFF',
		borderRadius : 6,
		padding : 5,
		marginLeft : 4,
		marginRight : 4,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});
