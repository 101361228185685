import React from 'react';
import firebase from "firebase/compat/app";

import { useState, useEffect, useContext } from 'react';
import { Typography, Breadcrumbs } from '@material-ui/core';
import { Link } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';
import { DateTime } from "luxon";

import { ToursContext } from "../store/ToursProvider";
import { TimeContext } from "../store/TimeProvider";

import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { GetLocalStorage, SetLocalStorage } from '.././Utils';

function Tours() {

	const { GetTours } = useContext(ToursContext);
	const { day_prod } = useContext(TimeContext);

	const title = 'Tournées';

	const [tours, setTours] = useState({});
	const [routes, setRoutes] = useState([]);
	const [coordinnates, setCoordinnates] = useState({});
	const [selected_date, setSelectedDate] = useState(GetLocalStorage('tours_selected_date') !== null ? DateTime.fromISO(GetLocalStorage('tours_selected_date')) : DateTime.fromISO(day_prod));

	const handleDateChange = (date) => {
		SetLocalStorage('tours_selected_date', date.toFormat('yyyyLLdd'));
		setTours({});
		setSelectedDate(date);
		return true;
	};


	useEffect(() => {

		console.log('>>', selected_date);

		GetTours(parseInt(selected_date.toFormat('yyyyLLdd')), (docs) => {

			var hash = {};
			docs.forEach(doc => {
				var data = doc.data();
				hash[data.tour] = data;
			});

			setTours(hash);
		});

	}, [selected_date]);


	useEffect(() => {

		if (Object.values(tours).length === 0) return;

		firebase.firestore().collection('routes_v2').where('date', '==', parseInt(selected_date.toFormat('yyyyLLdd'))).onSnapshot((docs) => {

			var hash_routes = {};
			var hash_tours = {};
			docs.forEach((doc) => {
				var data = doc.data();
				hash_routes[data.id] = data;
				hash_tours[data.tour] = data;
			});
			setRoutes(hash_routes);

			var hash = {};

			loop1:
			for (var i in tours) {

				var tour = tours[i];

				if (hash_tours[tour.tour] !== undefined) hash[tour.tour] = [];

				loop2:
				for (var j in tour.list) {
					if (hash_routes[tour.list[j]] === undefined) continue loop2;
					hash[tour.tour].push(hash_routes[tour.list[j]]);
				}
			}

			setCoordinnates(hash);
		});

	}, [tours, selected_date]);


	var colors = ['#50808e', '#654f6f', '#cce2a3', '#0a122a', '#9a031e', '#7adfbb', '#321325', '#cb793a', '#fcdc4d', '#804e49', '#4e6e58']

	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Accueil</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>
			
			<h2 style={{ paddingBottom: 0 }}>{title}</h2>

			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
				<div>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<KeyboardDatePicker
							id='tours_date'
							disableToolbar
							variant='inline'
							format='dd/MM/yyyy'
							margin='normal'
							label='Date'
							value={selected_date}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>
	
			<LoadScript googleMapsApiKey="AIzaSyAezkTsefd38GD4f13zlQ92C4P9I478I0g">

				<GoogleMap
					mapContainerStyle={{
						width: '100%',
						height: '600px'
					}}
					center={{
						lat: 48.866667,
						lng: 2.333333
					}}
					zoom={12}
				>


					{Object.values(coordinnates).map((c, i) => {

						var coordinnates = [];
						for (var k in c) {
							coordinnates.push(c[k].address.location);
						}

						return (
							<>
								<Polyline
									options={{
										strokeColor: colors[i],
										strokeWeight: 3,
									}}
									path={coordinnates}
								/>
								{c.map((r, j) => (
									<Marker
										icon={{
											path: 'M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0',
											fillColor: colors[i],
											fillOpacity: 0.5,
											scale: 2,
											strokeColor: colors[i],
											strokeWeight: 1,
										}}
										label={String(j + 1)}
										title={r.address.name}
										position={r.address.location}
									/>
								))}
							</>
						)}
					)}

				</GoogleMap>
			</LoadScript>

		</div>
	);
}

export default React.memo(Tours);

