import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faFileCsv, faList, faFile, faCircleDot } from '@fortawesome/free-solid-svg-icons'

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { TimeContext } from '../store/TimeProvider';
import { UsersContext } from "../store/UsersProvider";

import { Sort } from '.././Utils';
import { DateTime } from "luxon";


function Planograms() {

	const { today } = useContext(TimeContext);
	const { checkUserRights } = useContext(UsersContext);
	const { search } = useContext(ShakiContext);
	const { DuplicatePlanogram, GetPlanograms, GetShop } = useContext(ShopsContext);
	
	const [shop, setShop] = useState({});
	const [planograms, setPlanograms] = useState([]);

	var { shop_id } = useParams();

	const title = 'Planogrammes';
	const classes = useStyles();
	const history = useHistory();


	useEffect(() => {

		GetPlanograms(shop_id, (list) => {
			setPlanograms(list);
		});

	}, [shop_id]);


	useEffect(() => {

		GetShop(shop_id, (doc) => {
			setShop(doc.data());
		});

	}, [shop_id]);


	return (
		<div>
			
			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Dashboard</Link>
				<Link to='/shops'>Clients</Link>
				<Link to={'/shops/' + shop_id}>{shop.name}</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ marginBottom: 40 }}>{title}</h2>

			<Table className={classes.table} style={{ marginBottom: 20 }} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell style={{ width: '50%' }}>Nom</StyledTableCell>
						<StyledTableCell style={{ width: '40%' }}>Date d'application</StyledTableCell>
						<StyledTableCell style={{ width: '10%' }}>Actions</StyledTableCell>
					</TableRow>
				</TableHead>
			</Table>

			<TableContainer component={Paper}>
				<Table className={classes.table}>
					<TableBody>
						{planograms.sort((a, b) => Sort(a, b, 'date_start', 'asc')).map((row) => (
						<TableRow key={row.id} className={classes.tr}>
							<TableCell style={{ width: '50%' }} className={row.date_start <= today ? classes.td_current : classes.td}>
								<Link to={'/shops/' + row.shop_id + '/planograms/' + row.id}>
									{row.date_start <= today ? <FontAwesomeIcon icon={faCircleDot} size='sm' color='green'/> : '' } {row.name}
								</Link>
							</TableCell>
							<TableCell style={{ width: '40%' }} className={row.date_start <= today ? classes.td_current : classes.td}>{DateTime.fromISO(row.date_start).toFormat('dd LLL yyyy')}</TableCell>
							<TableCell style={{ width: '10%', display: 'flex', flexDirection: 'row' }} className={row.date_start <= today ? classes.td_current : classes.td}>
								<Button disabled={!checkUserRights('planograms_write')} onClick={(e) => DuplicatePlanogram(row)} color='primary' size='small' style={{ minWidth: 30 }}>
									<FontAwesomeIcon icon={faCopy} size="xl" />
								</Button>
							</TableCell>
						</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default React.memo(Planograms);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	}
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_rate_card: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#EDF0E5',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#DBE1CB',
		}
	},
	td: {
		padding: 12,
		paddingTop: 5,
		paddingBottom: 5,
	},
	td_current: {
		padding: 12,
	},
});
