import React, { useContext, useEffect, useState } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { UsersContext } from '../store/UsersProvider';

export const ToursContext = React.createContext({});

function ToursProvider({ children }) {


	const { user, checkUserRights } = useContext(UsersContext);


	const [tour_id, setTourId] = useState(null);
	const [tour, setTour] = useState({});


	const setCurrentTour = (id) => {
		if (tour_id === id) return;
		return setTourId(id);
	}


	const GetTours = (date, callback) => {
		return firebase.firestore().collection('tours').where('date', '==', date).onSnapshot(callback);
	}


	useEffect(() => {

		if (tour_id === null) return;
		if (!checkUserRights()) return;

		return firebase.firestore().collection('tours').doc(tour_id).onSnapshot((doc) => {

			var tour = doc.data();
			setTour(tour);
		});

		//return () => subscriber();

	}, [tour_id, user]);


	return(
		<ToursContext.Provider value={{

			GetTours: GetTours,
			tour: tour,
		}}>
			{children}
		</ToursContext.Provider>
	);
}

export default ToursProvider;
