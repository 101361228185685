import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Avatar} from '@material-ui/core';
import { Link } from "react-router-dom";

import { ShopsContext } from "../store/ShopsProvider";

function Stats() {

	const { shops } = React.useContext(ShopsContext);

	const title = 'Statistiques';
	const classes = useStyles();


	var month = 6;


	const [data, setData] = useState(null);
	const [production, setProduction] = useState(null);

	useEffect(() => {

		return firebase.firestore().collection('sales_report').where('month', '==', month).onSnapshot((querySnapshot) => {

			if (querySnapshot === null) return;

			var list = {};
			querySnapshot.forEach(doc => {
				list[doc.data().shop_id] = doc.data();
			});

			var total = {};

			loop1:
			for (var shop_id in list) {

				if (list[shop_id].id !== undefined) {
					delete list[shop_id];
					continue;
				}

				var count = {};
				loop2:
				for (var day in list[shop_id]) {

					if (day === 'month' || day === 'shop_id') continue loop2;

					for (var sku in list[shop_id][day]) {

						if (total[sku] === undefined) total[sku] = {sku: sku, value: 0};
						if (count[sku] === undefined) count[sku] = {sku: sku, value: 0};

						total[sku].value += list[shop_id][day][sku];
						count[sku].value += list[shop_id][day][sku];

						//if (count[sku].value >= max) max = count[sku].value;
					}
				}

				list[shop_id].by_sku = count;
			}

			list.count_sku = total;
			//list.max = max;

			setData(list);

		});

	}, []);

	useEffect(() => {

		if (data === null) return;

		return firebase.firestore().collection('shaki_orders').where('day', '>', 20210600).where('day', '<', 20210613).onSnapshot((querySnapshot) => {

			if (querySnapshot === null) return;

			var list = {max : 0};
			querySnapshot.forEach(doc => {

				if (data[doc.data().shop_id] !== undefined) {

					if (list[doc.data().shop_id] === undefined) list[doc.data().shop_id] = {};

					for (var sku in doc.data().products) {
						if (list[doc.data().shop_id][sku] === undefined) list[doc.data().shop_id][sku] = 0;
						list[doc.data().shop_id][sku] += doc.data().products[sku];

						list.max = Math.max(list[doc.data().shop_id][sku], list.max);
					}
				}

			});

			setProduction(list);
		});

	}, [data]);

	console.log(data);

	const DrawBars = (row) => {

		if (data[row.shop_id] === undefined) {
			return (<div></div>);
		}

		return (
			<div>
				{Object.values(data[row.shop_id].by_sku).map((sku) => (
					<DrawBar shop_id={row.shop_id} sku={sku}/>
				))}
			</div>
		);
	}


	const DrawBar = (row) => {

		var percent = String((row.sku.value / production.max) * 100) + "%";

		if (production[row.shop_id] !== undefined && production[row.shop_id][row.sku.sku] !== undefined) {
			var percent_production = String((production[row.shop_id][row.sku.sku] / production.max) * 100) + "%";
		}

		return (
			<div style={{ display: 'flex', width: '100%', alignItems: 'center', paddingBottom: 4 }}>
				<div style={{ width: '15%', margin: 2 }}>
					{row.sku.sku}
				</div>
				<div style={{ width: '85%' }}>
					<div style={{ display: 'flex' }}>
						<div style={{ backgroundColor : 'red', width : percent, padding: 2, margin : 1, fontSize: 10, color: 'white' }}>
							{row.sku.value}
						</div>
					</div>
					{percent_production !== undefined &&
						<div style={{ backgroundColor : 'blue', width : percent_production, padding: 2, margin : 1, fontSize: 10, color: 'white' }}>
							{production[row.shop_id][row.sku.sku]}
						</div>
					}
				</div>
			</div>
	);
	}


	if (data === null || production === null) return null;

	var list = Object.values(shops).filter((row) => {
		return row.name.search('Mono') === 0;
	});

	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Accueil</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{paddingBottom : 40}}>{title}</h2>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width : '12%' }}></StyledTableCell>
							<StyledTableCell style={{ width : '23%' }}>Magasins</StyledTableCell>
							<StyledTableCell style={{ width : '65%' }}></StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{list.map((row) => (
						<StyledTableRow key={row.id}>

							<StyledTableCell>
								<Link to={'/shops/' + row.id}>
								{row.picture !== undefined ?
									<Avatar
									src={row.picture}
									style={{width : 60, height : 60}}
									/>
								:
									<Avatar style={{width : 60, height : 60}}>{row.first_name !== undefined ? row.first_name.substr(0, 1) : 'A'}</Avatar>
								}
								</Link>
							</StyledTableCell>

							<StyledTableCell component="th" scope="row">
								<Link to={'/shops/' + row.id}>{row.name}</Link>
							</StyledTableCell>

							<StyledTableCell>
								<div>
									<DrawBars shop_id={row.id}/>
								</div>
							</StyledTableCell>

						</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

		</div>
	);
}

export default React.memo(Stats);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
