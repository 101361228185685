import React, { useEffect, useState } from 'react';
import { DateTime } from "luxon";

export const TimeContext = React.createContext({});

function TimeProvider({ children }) {

	const [today, setToday] = useState(DateTime.local());
	const [day_prod, setDayProd] = useState(parseInt(today.toFormat('yyyyLLdd')));

	// Check Date
	useEffect(() => {

		var inter = setInterval(() => {

			if (today.toFormat('yyyyLLdd') === DateTime.local().toFormat('yyyyLLdd')) {

			} else {
				setToday(DateTime.local())
				setDayProd(parseInt(DateTime.local().toFormat('yyyyLLdd')));
				clearInterval(inter);
			}

		}, 1800000);

	}, [today]);


	const changeDate = (inc) => {

		if (inc === 0) {
			return setDayProd(parseInt(today.toFormat('yyyyLLdd')));
		}

		return setDayProd(parseInt(today.plus({days: inc}).toFormat('yyyyLLdd')));
	}


	return(
		<TimeContext.Provider value={{
			today: parseInt(today.toFormat('yyyyLLdd')),
			day_prod: day_prod,
			changeDate: changeDate
		}}>
			{children}
		</TimeContext.Provider>
	);
}

export default React.memo(TimeProvider);
