import React from 'react';

import { TextField, Divider, Typography } from '@material-ui/core';
import { Controller } from "react-hook-form";

import { ShakiContext } from "../store/ShakiProvider";

function SaleForm(data) {

	const { products_by_sku } = React.useContext(ShakiContext);

	return (
		<div style={{ display: "flex", flexDirection: "row", alignItems: 'center', padding: 10 }}>

			<div style={{ width: '20%' }}>
				<Typography variant="body2">
					{data.sku}
				</Typography>
			</div>

			<div style={{ width: '50%' }}>
				<Typography variant="body2">
					{products_by_sku[data.sku].name}
				</Typography>
			</div>

			<div style={{ width: '30%' }}>
				<Controller as={TextField}
					control={data.control}
					name={data.sku}
					label='Quantité'
					type='number'
					defaultValue=''
					InputProps={{
						inputProps: {
							min: 0
						}
					}}
					style={{ width: 60 }}
				/>
			</div>

			<Divider />

		</div>
	);
}

export default React.memo(SaleForm);
