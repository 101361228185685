import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { ShakiContext } from "./store/ShakiProvider";
import { DateTime } from "luxon";


const WeekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
const Months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];


const container_types = {
	cagette_plastic : 'Cagette plastique',
	cagette_carton : 'Cagette carton',
	bakery_box : 'Boîte patissière',
	carton_small : 'Carton petit',
	kanga : 'Kangabox',
	lunch_bag : 'Sac kraft'
};


function GetOrderKey (l = 8) {
	return Math.random().toString(20).substr(2, l);
};


function GetTotalWeight (products, id = null) {

	const { products_by_sku } = React.useContext(ShakiContext);

	var amount = 0;
	for (var sku in products) {
		if (products_by_sku[sku] === undefined) continue;
		amount += (products_by_sku[sku].weight * products[sku])
	};

	return parseInt(Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(amount / 1000));
};


function GetTotalPrices (rate_card, pricelist, products, format = 'formated') {

	var total = 0;
	var total_tva = 0;
	var tva = 0;
	var ttc = 0;
	var delivery_fee = pricelist.delivery_fee !== undefined ? pricelist.delivery_fee : 25;
	var franco = (pricelist.franco !== undefined && pricelist.franco >= 0 ) ? pricelist.franco : 0;

	if (pricelist.prices !== undefined) {
		for (var sku in products) {

			var amount = GetPrice(rate_card, pricelist, null, sku, products[sku], false);

			if (pricelist.prices[sku] === undefined) {
				console.log(rate_card.name, sku);
				continue;
			};

			total += amount;
			total_tva += (amount * ((pricelist.prices[sku].tva_percent / 100) + 1)) - amount;
		};
	};

	delivery_fee = (total < franco) ? delivery_fee : 0;
	total_tva += (delivery_fee * 0.2);

	if (format === 'formated') return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(total);
	if (format === 'int') return parseFloat(total);
	if (format === 'object') return {
		total: total,
		franco: franco,
		delivery_fee: delivery_fee,
		tva: total_tva,
		ttc: total + total_tva + delivery_fee
	};
};


function GetPrice (rate_card, pricelist, product_id = null, sku = null, amount = 0, format = true) {

	const { products } = useContext(ShakiContext);

	function f(value, format) {
		var v = parseFloat(value).toFixed(2);
		if (format) return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
		return parseFloat(v);
	}

	if (rate_card.prices === undefined || pricelist.prices === undefined) amount = 0;
	if (amount === undefined) amount = 0;
	if (amount === 0) return f(0, format);
	if (pricelist === null) return f(0, format);

	var rev_share = pricelist.rev_share !== undefined ? pricelist.rev_share : false;

	if (sku === null) {
		if (products[product_id] === undefined) return f(0, format);
		sku = products[product_id].sku;
	}

	if (pricelist.prices[sku] === undefined) return f(0, format);
	if (rate_card.prices[sku] === undefined) return f(0, format);

	var price = rev_share === true ? pricelist.prices[sku].retail_price : rate_card.prices[sku];
	var discount = (pricelist.prices[sku].discount !== undefined) ? parseFloat(pricelist.prices[sku].discount) : 0;

	// Discount range calculation
	if (pricelist.prices[sku].discounts !== undefined) {
		for (var k in pricelist.prices[sku].discounts) {
			if (amount >= pricelist.prices[sku].discounts[k].range) discount = parseFloat(pricelist.prices[sku].discounts[k].discount)
		};
	};


	return f((Math.round(price * (100 - discount)) / 100) * amount, format);
};




function GetLocalStorage (key, fallback = null) {
	return (localStorage.getItem(key) !== null ? localStorage.getItem(key) : fallback);
};


function SetLocalStorage (key, value) {
	return localStorage.setItem(key, value);
};


function Sort (a, b, field, order_by = 'desc') {
	if (b[field] < a[field]) return order_by === 'desc' ? -1 : 1;
	if (b[field] > a[field]) return order_by === 'desc' ? 1 : -1;
	return 0;
};


const FetchData = (endpoint, user_token, callback) => {

	var xhttp = new XMLHttpRequest();
	xhttp.onreadystatechange = function() {
		//if (this.readyState === 4 && this.status === 200) {}
	};

	xhttp.onload = function(e) {

		if (this.status === 200) {

			callback(this.response);

		} else {
			//deal with your error state here
		}
	};

	xhttp.open("GET", endpoint, true);
	xhttp.setRequestHeader('Authorization', 'Bearer ' + user_token);
	xhttp.send();
};


const GetImagePath = (images, label, size = 'small') => {

	if (images === undefined) return '';
	if (images[label] === undefined) return '';
	if (images[label][size + '_path'] === undefined) return '';

	return 'https://firebasestorage.googleapis.com/v0/b/shakishaki-243509.appspot.com/o/' + encodeURIComponent(images[label][size + '_path']) + '?alt=media';
};


const UploadImage = (type, category, id, file, callback) => {

	if (category === null) category = GetOrderKey(8);
	var name = id + '_' + Math.round(DateTime.local().toSeconds());
	var path = 'images/' + type + '/' + category + '/' + name + '.jpg';

	var storage = getStorage();
	const storageRef = ref(storage, path);
	const uploadTask = uploadBytesResumable(storageRef, file, {cacheControl: 'public, max-age=86400'});

	uploadTask.on('state_changed',
		(snapshot) => {
			const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			console.log(progress);
		}, (error) => {
			alert(error);
		}, () => {
			callback(path);
		}
	);
};


const DownloadFile = (path) => {

	var storage = getStorage();

	getDownloadURL(ref(storage, path)).then((url) => {
		window.open(url, '_blank');
	});

	return false;
};


const FetchFile = (user_token, uri, filename) => {

	console.log('fetch', uri, filename);

	return FetchData(uri, user_token, (data) => {

		// Create a new Blob object using the response data of the onload object
		var blob = new Blob([data], {type: 'text/csv'});

		//Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
		let a = document.createElement("a");
		a.style = "display: none";
		document.body.appendChild(a);

		//Create a DOMString representing the blob and point the link element towards it
		let url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = DateTime.local().toFormat('yyyy_LL_dd') + '_lunafood_' + filename + '.csv';

		//programatically click the link to trigger the download
		a.click();

		//release the reference to the file by revoking the Object URL
		window.URL.revokeObjectURL(url);
	});
};


const CleanNumber = (value) => {
	return value.toFixed(2);
};


const getGtin14 = (ean13) => {
	if (ean13 === undefined) return '';
	return '1' + ean13.substr(0, 12) + CalculateCheckDigitEAN14(String(1) + ean13.substr(0, 12));
};


const CalculateCheckDigitEAN14 = (code) => {
	var sum = 0;
	for (var i = 0; i < code.length; i++) {
		var digit = parseInt(code[i]);
		if (i % 2 == 0) {
			sum += digit * 3;
		} else {
			sum += digit * 1;
		}
	}
	var checkDigit = (10 - (sum % 10)) % 10;
	return checkDigit;
};


export { getGtin14, CleanNumber, FetchData, FetchFile, GetTotalWeight, GetPrice, GetTotalPrices, GetOrderKey, WeekDays, Months, GetLocalStorage, SetLocalStorage, Sort, container_types, UploadImage, GetImagePath, DownloadFile };
