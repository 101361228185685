import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';

import { ShakiContext } from "../store/ShakiProvider";
import { TimeContext } from "../store/TimeProvider";

import { GetLocalStorage, SetLocalStorage, Sort } from '.././Utils';

import { DateTime } from "luxon";


function Production(data) {

	const { products_by_sku } = useContext(ShakiContext);
	const { today, day_prod } = useContext(TimeContext);

	var { shop_id } = useParams();
	var { include } = data;

	const title = 'Production';

	const classes = useStyles();
	const [selected_date, setSelectedDate] = useState(GetLocalStorage('production_selected_date') !== null ? DateTime.fromISO(GetLocalStorage('production_selected_date')) : DateTime.fromISO(day_prod));

	const handleDateChange = (date) => {
		SetLocalStorage('production_selected_date', date.toFormat('yyyyLLdd'));
		setSelectedDate(date);
		return true;
	};

	var days = [];
	for (var j = 0; j < 7; j++) {
		days.push(selected_date.plus({ days: j }).toFormat('dd/LL'))
	}

	const [production, setProduction] = useState(null);
	useEffect(() => {

		return (() => {

			var p = [];
			var hash = {};

			for (var i = 0; i < 7; i++) {

				var query_date = selected_date.plus({ days: i }).toFormat('yyyyLLdd');

				p.push(firebase.firestore().collection('production').doc(String(query_date)).get());
			}

			Promise.all(p).then((values) => {

				loop1:
				for (var i in values) {

					var data = values[i].data();

					loop2:
					for (var shift in data) {

						if (shift === 'day_prod' || shift === 'economat') continue loop2;
						if (hash[shift] === undefined) hash[shift] = {};

						for (var product in data[shift]) {

							if (hash[shift][product] === undefined) hash[shift][product] = {id: product, days: []};

							hash[shift][product].days[i] = data[shift][product];
						}
					}
				}

				setProduction(hash);
			});

		})();


	}, [selected_date]);


	const countProduct = row => {

		var count = 0;
		var unit = '';

		for (var i in row.days) {
			if (row.days[i] === undefined) continue;
			if (row.days[i].unit !== undefined) unit = ' ' + row.days[i].unit;
			count += row.days[i].quantity;
		}


		return (count % 1 === 0) ? count + unit : count.toFixed(2) + unit;
	};


	/*
	const totalProduct = (list) => {
		var total = 0;
		for (var i in list) {
			total += countProduct(list[i].productList);
		}
		return total;
	}
	*/


	/*
	const totalWeight = (list) => {
		var total = 0;
		for (var i in list) {
			total += GetTotalWeight(list[i].products);
		}
		return total;
	}
	*/


	const getBackgroundColor = (row, day) => {

		if (row.days[day] !== undefined && row.days[day].status === 'completed') {
			return (selected_date.plus({ days: day }).toFormat('yyyyLLdd') == today) ? '#95EF8F' : '#B0FFAE';
		} else {
			return (selected_date.plus({ days: day }).toFormat('yyyyLLdd') == today) ? '#ffa2a2' : '';
		}
	}


	if (production === null) return null;
	if (Object.values(products_by_sku).length === 0) return null;

	return (
		<div>

			{(!include && shop_id !== undefined) &&
				<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
					<Link to='/'>Accueil</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>
			}

			<h2 style={{ paddingBottom: 40 }}>{title}</h2>

			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
				<div>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<KeyboardDatePicker
							id='production_date'
							disableToolbar
							variant='inline'
							format='dd/MM/yyyy'
							margin='normal'
							label='J+0'
							value={selected_date}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>

			<TableContainer component={Paper} style={{ marginBottom: 20 }}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width: '8%' }}>SKU</StyledTableCell>
							<StyledTableCell style={{ width: '20%' }}>Nom</StyledTableCell>

							{days.map((row, index) => (
								<StyledTableCell key={'day_' + index} style={{ width: '9%' }} align="right">{row}</StyledTableCell>
							))}

							<StyledTableCell align="right"><b>Total</b></StyledTableCell>
						</TableRow>
					</TableHead>

				</Table>

			</TableContainer>

			{Object.entries(production).sort().map((item, i) => {
				return(
				<>
				<h3 style={{ marginBottom: 10 }}>Shift {item[0]}</h3>
				<TableContainer component={Paper} style={{ marginBottom: 20 }}>
					<Table className={classes.table} aria-label="customized table">

						<TableBody>

							{Object.values(production[item[0]]).sort((a, b) => Sort(a, b, 'id', 'asc')).map((row, index) => {
								if (products_by_sku[row.id] === undefined) {
									console.log(row.id + ' is missing.')
									return null;
								}
								return (
								<StyledTableRow key={String(row.id + '_' + index)}>

									<StyledTableCell style={{ width: '8%' }}>{row.id}</StyledTableCell>
									<StyledTableCell style={{ width: '20%' }}>{products_by_sku[row.id].name}</StyledTableCell>

									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 0) }} align="right">{row.days[0] === undefined ? '-' : row.days[0].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 1) }} align="right">{row.days[1] === undefined ? '-' : row.days[1].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 2) }} align="right">{row.days[2] === undefined ? '-' : row.days[2].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 3) }} align="right">{row.days[3] === undefined ? '-' : row.days[3].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 4) }} align="right">{row.days[4] === undefined ? '-' : row.days[4].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 5) }} align="right">{row.days[5] === undefined ? '-' : row.days[5].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%', backgroundColor : getBackgroundColor(row, 6) }} align="right">{row.days[6] === undefined ? '-' : row.days[6].quantity}</StyledTableCell>
									<StyledTableCell style={{ width: '9%' }} align="right"><b>{countProduct(row)}</b></StyledTableCell>

								</StyledTableRow>
								)
							})}

						</TableBody>
					</Table>

				</TableContainer>
				</>
				)
			})}

		</div>
	);
}

export default React.memo(Production);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	}
});
