import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar, IconButton } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faFileCsv, faList, faFile, faCircleDot } from '@fortawesome/free-solid-svg-icons'
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";
import { OrdersContext } from '../store/OrdersProvider';

import { DateTime } from "luxon";
import { GetPrice, GetTotalPrices, GetTotalWeight, GetImagePath, DownloadFile, Sort } from '.././Utils';


function OrderCredit (data) {

	const { order_id, onClose, selectedValue, open } = data;

	const { GetOrder, saveCredited } = useContext(OrdersContext);
	const { products_by_sku } = useContext(ShakiContext);

	const [credited, setCredited] = useState({});
	const [order, setOrder] = useState({products:{}});
	const [delivered, setDelivered] = useState({})

	const classes = useStyles();


	useEffect(() => {

		if (open === false) return;

		GetOrder(order_id, (data) => {
			console.log('GetOrder')
			setOrder(data);
			setDelivered(data.delivered !== undefined ? {...data.delivered} : {...data.products});
		});

		setCredited({})

	}, [open]);


	const handleClose = () => {
		onClose(selectedValue);
	};


	const handleListItemClick = (value) => {
		onClose(value);
	};


	const incr = (sku, incr) => {
		if (credited[sku] === undefined) credited[sku] = 0;
		credited[sku] += incr;
		setCredited({...credited});
	}


	const getTotal = (obj) => {
		var t = 0;
		for (var sku in obj) t += obj[sku];
		return t;
	}


	return (
		<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth='lg' scroll='paper'>

			<DialogTitle>
				<Table>
				<TableHead>
					<TableRow>
						<TableCell width='12%'><b>SKU</b></TableCell>
						<TableCell width='24%'><b>Nom</b></TableCell>
						<TableCell width='10%'><b>Commandé</b></TableCell>
						<TableCell width='10%'><b>Livré</b></TableCell>
						<TableCell width='16%' align="right"><b>Avoir</b></TableCell>
						<TableCell width='14%' align="right"><b>PU</b></TableCell>
						<TableCell width='14%' align="right"><b>Total HT</b></TableCell>
					</TableRow>
				</TableHead>
				</Table>
			</DialogTitle>
			<DialogContent dividers={true}>
				<Table>

					<TableBody>
						{Object.entries(order.products).sort((a, b) => {return a[0] > b[0] ? 1 : -1}).map((row) => {

						var sku = row[0];
						var amount = row[1];

						return(
						<StyledTableRow key={row.sku}>
							<StyledTableCell width='12%'>{sku}</StyledTableCell>
							<StyledTableCell width='24%'>{products_by_sku[sku].name}</StyledTableCell>
							<StyledTableCell width='10%'>{amount}</StyledTableCell>
							<StyledTableCell width='10%'>{delivered[sku] !== undefined ? delivered[sku] : '-'}</StyledTableCell>
							<StyledTableCell width='16%' align="right">

								{(credited[sku] !== undefined && credited[sku] > 0) &&
									<IconButton size="small" onClick={() => incr(sku, -1)}>
										<RemoveIcon fontSize="inherit"/>
									</IconButton>
								}

								{credited[sku] !== undefined ? credited[sku] : 0}

								<IconButton size="small" onClick={() => incr(sku, 1)}>
									<AddIcon fontSize="inherit"/>
								</IconButton>

							</StyledTableCell>
							<StyledTableCell width='14%' align="right">{GetPrice(order.rate_card, order.pricelist, null, sku, 1, true)}</StyledTableCell>
							<StyledTableCell width='14%' align="right">{GetPrice(order.rate_card, order.pricelist, null, sku, credited[sku], true)}</StyledTableCell>
						</StyledTableRow>
						)})}
					</TableBody>
				</Table>
			</DialogContent>

			<DialogActions style={{ padding: 16, color: 'white', backgroundColor: 'darkslateblue' }}>

				{order.id !== undefined &&
				<div>
					<b>Total</b>&nbsp;&nbsp;{GetTotalPrices(order.rate_card, order.pricelist, credited)}
				</div>
				}

				<Button disabled={getTotal(credited) === 0} onClick={(e) => {saveCredited(order.id, credited); handleClose(); return true;}} variant='contained' color='primary' size="small">
					Créer un avoir
				</Button>

			</DialogActions>

		</Dialog>
	);
};

export default React.memo(OrderCredit);

const StyledTableCell = withStyles((theme) => ({
	head : {
		//backgroundColor : '#000',
		//color : theme.palette.common.white,
	},
	body : {
		//fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
	root : {
		fontWeight : '500',
	},
	iconDirectionDesc : {},
	iconDirectionAsc : {}
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
		//	backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		//minWidth: 700,
	},
	container : {
		backgroundColor : '#000',
		color : '#FFF',
		borderRadius : 6,
		padding : 5,
		marginLeft : 4,
		marginRight : 4,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});


