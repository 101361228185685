import React from 'react';

import { TextField, Typography} from '@material-ui/core';


function TemplateLine(row) {

	var register = row.register;
	var index = row.index;
	var field = row.field;

	return (

		<div style={{ display: 'flex', flexDirection: 'row', alignItems : 'center', justifyContent : 'space-between', width: '100%' }}>

			<div>
				<Typography variant="body2">
					{row.product.name}
				</Typography>
			</div>

			<div>
				<TextField
					inputRef={register()}
					name={`list[${index}][${row.product.sku}]`}
					label='Quantité'
					defaultValue={`${field[row.product.sku]}`}
					InputProps={{
						inputProps: {
							min: 0
						}
					}}
					style={{ width: 70 }}
				/>
			</div>

		</div>
	);
}

export default React.memo(TemplateLine);



