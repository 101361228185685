import React from 'react';

import { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import bimbang_logo from '../assets/logo/bimbang.svg';
import luisa_logo from '../assets/logo/luisa_basilio.svg';
import shakishaki_logo from '../assets/logo/shakishaki.svg';
import yaffa_logo from '../assets/logo/yaffa.svg';

function LunaMenu() {

	const { setAppBarVisibility } = React.useContext(ShakiContext);
	//const { GetShop, price_list } = React.useContext(ShopsContext);
	//const { GetLunaProducts, remote_config } = React.useContext(ShakiContext);

	var { shop_id } = useParams();

	var query_brands = new URLSearchParams(useLocation().search).get('brands');
	query_brands = query_brands.split(',');

	const [shop, setShop] = useState({});
	const [luna_products, setLunaProducts] = useState({});

	const [i, setI] = useState(0);
	const [menu, setMenu] = useState({});


	useEffect(() => {

		setAppBarVisibility(false);

		setInterval(function () {
			setI(i + 1);
		}, 60000);

	}, []);


	useEffect(() => {

		var xmlhttp = new XMLHttpRequest();
		xmlhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
				setMenu(JSON.parse(this.responseText));
			}
		};

		xmlhttp.open('GET', 'https://firebasestorage.googleapis.com/v0/b/shakishaki-243509.appspot.com/o/menu%2F' + shop_id + '.json?alt=media', true);
		xmlhttp.send();

	}, [i]);

/*
	const hasDiscount = (product) => {

		product.highlighted = false;

		if (shop.products_pricing === undefined) return product;
		if (shop.products_pricing[product.id] === undefined) return product;

		if (shop.products_pricing[product.id].discount !== undefined && shop.products_pricing[product.id].discount < 0) {
			product.old_price = product.price;
			product.price = product.old_price + shop.products_pricing[product.id].discount;
		}

		if (shop.products_pricing[product.id].highlighted !== undefined) {
			product.highlighted = shop.products_pricing[product.id].highlighted
		}

		return product;
	}


	const isAvailable = (product) => {

		product.sold_out = false;
		product.available = product.enabled;

		if (shop.products_availabilities !== undefined &&
			shop.products_availabilities[product.id] !== undefined &&
			shop.products_availabilities[product.id] === false) {
			product.sold_out = true;
		}

		for (var j in product.composition_base) {
			var base = product.composition_base[j];

			if (price_list[shop.pricelist_id].prices[base.sku] === undefined ||
				(price_list[shop.pricelist_id].prices[base.sku] !== undefined && price_list[shop.pricelist_id].prices[base.sku].enabled === false)
			) {
				product.available = false;
				continue;
			}

			if (shop.products_availabilities !== undefined &&
				shop.products_availabilities[base.sku] !== undefined &&
				shop.products_availabilities[base.sku] === false) {
				product.sold_out = true;
			}
		}

		return product;
	}


	useEffect(() => {


		GetShop(shop_id, (doc) => {
			setShop(doc.data());
		});

	}, [shop_id]);


	useEffect(() => {

		if (Object.values(shop).length === 0) return;
		if (Object.values(price_list).length === 0) return;

		GetLunaProducts((docs) => {

			if (docs === null) return;

			const list = {};
			docs.forEach(doc => {
				var data = doc.data();
				data = hasDiscount(data);
				data = isAvailable(data);
				list[doc.id] = data;
			});

			setLunaProducts(list);
		});

	}, [shop, price_list]);


	useEffect(() => {

		if (Object.values(remote_config).length === 0) return;

		var list = [];
		var brands = {}

		for (var i in luna_products) {

			var product = luna_products[i];

			if (product.available === false) continue;

			var brand = product.brand;

			if (brands[brand] === undefined) {
				brands[brand] = [];
			}

			var category = product.category;
			var lookup_index = brands[brand].findIndex(item => item.name === category);

			if (lookup_index === -1) {
				brands[brand].push({
					name: category,
					list: []
				});
				lookup_index = brands[brand].length - 1;
			}

			brands[brand][lookup_index].list.push(product);
		}

		for (var brand in brands) {
			brands[brand].sort(function(a, b) {
				return remote_config.luna_categories_order.indexOf(a.name) - remote_config.luna_categories_order.indexOf(b.name)
			});
		}

		setBrands(brands);

	}, [luna_products, remote_config]);
*/

	const Menu = (data) => {

		//if (remote_config.brands === undefined) return null;
		//var main_color = remote_config.brands[data.brand].main_color;

		var list = [];
		for (var i in menu.data) {
			if (menu.data[i].key === data.brand) {
				list = menu.data[i].categories;
				var main_color = menu.data[i].main_color;
			}
		}

		if (list.length === 0) return null;

		return (

			<div style={{ margin: '5vw', marginBottom: '5vw', border: 'solid', borderWidth: 1, borderColor: main_color }} >

				<div style={{ backgroundColor: main_color, padding: '2vw' }}>
					{data.brand === 'shaki_shaki' &&
						<img src={shakishaki_logo} style={{ height : '6vw' }} alt=''/>
					}
					{data.brand === 'luisa_basilio' &&
						<img src={luisa_logo} style={{ height : '6vw' }} alt=''/>
					}
					{data.brand === 'yaffa' &&
						<img src={yaffa_logo} style={{ height : '6vw' }} alt=''/>
					}
					{data.brand === 'bim_bang' &&
						<img src={bimbang_logo} style={{ height : '7vw' }} alt=''/>
					}
				</div>

				<div style={{ padding: 20 }}>

						{list.map((category, i) => (
							<div key={'category_' + i}>

								<div style={{ marginBottom: 5, borderBottom: 'solid', borderBottomWidth: '0.3vw', borderColor: main_color, textTransform: 'uppercase', fontFamily: 'Bebas Neue', fontSize: '4vw' }}>
									{category.name}
								</div>

								<div style={{ display : 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: 20 }}>
								{category.products.map((product, j) => (
									<div key={product.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '47%', fontFamily: 'Barlow Condensed', fontSize: '2.8vw' }}>

										<div style={{ width: '75%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: product.sold_out ? '#666' : '#000' }}>
											{product.name}
										</div>

										{(!product.sold_out && product.old_price !== undefined) &&
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div style={{ textDecorationLine: 'line-through', marginRight: 5, color: '#999' }}>
													{product.old_price.toFixed(2)}€
												</div>
												<div>{product.price.toFixed(2)}€</div>
											</div>
										}

										{(!product.sold_out && product.old_price === undefined) &&
											<div>{product.price.toFixed(2)}€</div>
										}

										{product.sold_out &&
											<div style={{ color: '#666' }} >
												épuisé
											</div>
										}

									</div>
								))}
								</div>
							</div>
						))}

				</div>

			</div>
		);
	}

	return (
		<>
			{query_brands.map((brand, i) => (
				<Menu key={brand} brand={brand} />
			))}
			<Player
				autoplay
				loop
				src={require('../assets/lottie/shiba.json')}
				style={{ position: 'absolute', bottom: 0, left: '-10vw', height: '20vw', width: '20vw', transform: [{rotateY: '180deg'}] }}
			>
				<Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
			</Player>
		</>
	);
}

export default React.memo(LunaMenu);


