import React from 'react';

import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { TextField, Typography, Breadcrumbs, Button, IconButton, Select, InputLabel, FormControl} from '@material-ui/core';
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';

import { UsersContext } from "../store/UsersProvider";
import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";

import { WeekDays, GetOrderKey } from '.././Utils';
import { setRoutesConf, getRoutesConfs } from '.././Store';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'


function ProductionLines() {

	var { users_hash } = useContext(UsersContext);
	var { setOpenSnackBar, getRemoteConf, products, products_by_sku, getProductionLinesConf, setProductionLinesConf } = useContext(ShakiContext);

	const [input_value, setInputValue] = useState({});
	const [production_lines, setProductionLines] = useState({});
	const [brands, setBrands] = useState({});
	const [labelling_machines, setLabellingMachines] = useState({});


	useEffect(() => {

		getProductionLinesConf((data) => {
			setProductionLines(data);
		});

		setBrands(getRemoteConf('brands'));
		setLabellingMachines(getRemoteConf('labelling_machines'));

	}, []);


	const brandLookup = (brand) => {

		for (var i in brands) {
			if (brands[i].name === brand) return brands[i]
		};

	return {secondary_color: '#DDD'}
	};


	const moveCard = useCallback((dragIndex, hoverIndex, key, line_id) => {

		console.log('movecard', dragIndex, hoverIndex, key, line_id);

		const dragCard = production_lines[line_id].order[dragIndex];

		production_lines[line_id].order.splice(dragIndex, 1);
		production_lines[line_id].order.splice(hoverIndex, 0, dragCard)

		setProductionLines({...production_lines});

	}, [production_lines]);


	const handleDrop = useCallback((line_id, item) => {

		console.log('handleDrop',line_id, item);
		production_lines[line_id].order.splice(item.index, 1);

		setProductionLines({...production_lines});

    }, [production_lines]);


    const addProduct = (line_id) => {

    	if (input_value[line_id] === undefined || input_value[line_id] === null) {
    		console.log('nothing to add');
    		return false;
    	}

    	var sku = input_value[line_id].sku;

		console.log('addProduct to line', line_id, sku);

		// looking in all shift's day
		for (var i in production_lines) {

			for (var j in production_lines[i].order) {

				var current = production_lines[i].order[j];

				if (current.sku === sku) {
					production_lines[i].order.splice(j, 1);
					break;
				};
			};
		};

		production_lines[line_id].order.push({
			sku : sku
		});

		setProductionLines({...production_lines});

		input_value[line_id] = null;
		setInputValue({...input_value});

		return true;
	};


	const setLineName = (line_id, value) => {
		production_lines[line_id].name = value;
		setProductionLines({...production_lines});
	};


	const setCapacityPerHour = (line_id, value) => {
		production_lines[line_id].capacity_per_hour = parseInt(value);
		setProductionLines({...production_lines});
	};


	const setTotalOperators = (line_id, value) => {
		production_lines[line_id].total_operators = parseInt(value);
		setProductionLines({...production_lines});
	};


	const setPrimaryLeader = (line_id, value) => {
		production_lines[line_id].primary_leader = value;
		setProductionLines({...production_lines});
	};


	const setSecondaryLeader = (line_id, value) => {
		production_lines[line_id].secondary_leader = value;
		setProductionLines({...production_lines});
	};


	const setLabellingMachine = (line_id, value) => {
		production_lines[line_id].labelling_machine = value;
		setProductionLines({...production_lines});
	};


	const save = () => {
		return setProductionLinesConf(production_lines, (status) => { if (status) setOpenSnackBar(true) });
	};


	const renderShift = (data, index) => {

		var line_id = data[0];
		data = data[1];

		console.log('>', line_id, data)

		return (
			<div key={line_id} style={{display : 'flex', flexDirection : 'column', marginRight : 10, width : 250}}>

				<div style={{ marginBottom: 10 }}>


					<TextField
						label="Nom"
						style={{ width: '100%', marginBottom: 10 }}
						defaultValue={data.name}
						onChange={(e) => {setLineName(line_id, e.target.value)}}
					/>

					<FormControl style={{ width: '100%', marginBottom: 10  }} >
						<InputLabel>Chef de ligne</InputLabel>
						<Select
							native
							value={data.primary_leader}
							onChange={(e) => setPrimaryLeader(line_id, e.target.value)}
						>
							<option value=''>---</option>
							{Object.entries(users_hash).filter(row => row[1].job !== undefined && row[1].job === 'conducteur_ligne').map((operator, i) => (
								<option value={operator[0]}>{operator[1].first_name} {operator[1].last_name}</option>
							))}
						</Select>
					</FormControl>

					<FormControl style={{ width: '100%', marginBottom: 10  }} >
						<InputLabel>Chef de ligne secondaire</InputLabel>
						<Select
							native
							value={data.secondary_leader}
							onChange={(e) => setSecondaryLeader(line_id, e.target.value)}
						>
							<option value=''>---</option>
							{Object.entries(users_hash).filter(row => row[1].job !== undefined && row[1].job === 'conducteur_ligne').map((operator, i) => (
								<option value={operator[0]}>{operator[1].first_name} {operator[1].last_name}</option>
							))}
						</Select>
					</FormControl>

					<FormControl style={{ width: '100%', marginBottom: 10 }} >
						<InputLabel>Nombre d'opérateurs</InputLabel>
						<Select
							native
							value={data.total_operators}
							onChange={(e) => setTotalOperators(line_id, e.target.value)}
						>
							<option value='1'>1</option>
							<option value='2'>2</option>
							<option value='3'>3</option>
							<option value='4'>4</option>
							<option value='5'>5</option>
							<option value='6'>6</option>
							<option value='7'>7</option>
							<option value='8'>8</option>
							<option value='9'>9</option>
							<option value='10'>10</option>
							<option value='11'>11</option>
							<option value='12'>12</option>
							<option value='13'>13</option>
							<option value='14'>14</option>
							<option value='15'>15</option>
						</Select>
					</FormControl>

					<FormControl style={{ width: '100%', marginBottom: 10 }} >
						<InputLabel>Etiqueteuse</InputLabel>
						<Select
							native
							value={data.labelling_machine}
							onChange={(e) => setLabellingMachine(line_id, e.target.value)}
						>
							{labelling_machines.map((item, index) => (
								<option value={item.id}>{item.name}</option>
							))}
						</Select>
					</FormControl>

					<TextField
						label="Capacité / hour"
						style={{ width: '100%' }}
						defaultValue={data.capacity_per_hour}
						onChange={(e) => {setCapacityPerHour(line_id, e.target.value)}}
					/>

				</div>

				<div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 10 }}>

					<Autocomplete
						id={'search_' + line_id}
						value={input_value[line_id]}
						onChange={(event, newValue) => {
							input_value[line_id] = newValue;
							setInputValue({...input_value});
						}}
						options={Object.values(products).filter((row) => row.sub_recipe === undefined || row.sub_recipe === false).sort((a, b) => a.sku > b.sku ? 1 : -1)}
						getOptionLabel={(option) => option.sku + ' - ' + option.name}
						style={{ width: '88%'}}
						renderInput={(params) => <TextField InputLabelProps={{ shrink: true }} {...params} label="Ajouter un produit" />}
					/>

					<IconButton onClick={(e) => {addProduct(line_id)}} aria-label="add" size="small" style={{ width : '12%', height : 30 }} >
						<AddIcon fontSize="inherit"/>
					</IconButton>

				</div>

				<div style={{ fontSize : 13, borderRadius : 10, border: '1px solid gray', padding : 2, height : '100%' }}>
					{data.order.map((item, index) => renderCard(item, index, line_id))}
				</div>

				<Dustbin accept={line_id} lastDroppedItem={null} onDrop={(item) => handleDrop(line_id, item)} key={'bin_' + line_id} line_id={line_id} />
			</div>
		);
	};


	const renderCard = (item, index, line_id) => {
		return (<Card key={item.sku} id={item.sku} index={index} uniq_key={line_id} line_id={line_id} text={products_by_sku[item.sku] !== undefined ? products_by_sku[item.sku].sku + ' - ' + products_by_sku[item.sku].name : '...'} item={item} moveCard={moveCard} secondary_color={products_by_sku[item.sku] !== undefined ? brandLookup(products_by_sku[item.sku].brand).secondary_color : '#FFF'} />);
	};


    return (
    	<DndProvider backend={HTML5Backend}>

    		<Breadcrumbs aria-label="breadcrumb" style={{paddingBottom : 20}}>
				<Link to='/'>Accueil</Link>
				<Typography>Lignes de production</Typography>
			</Breadcrumbs>

			<h2 style={{ paddingBottom: 40 }}>Lignes de production</h2>

			<div style={{ display : 'flex', alignItems : 'center', paddingBottom: 30 }}>
				<div style={{display : 'flex'}}>
					{Object.entries(production_lines).sort((a, b) => a[1].index > b[1].index ? 1 : -1).map((line, i) => renderShift(line, i))}
				</div>
			</div>

    		<Button variant="outlined" color="default" onClick={(e) => {save()}}>
				Enregistrer
			</Button>

		</DndProvider>
	);
}


export default React.memo(ProductionLines);


const Card = ({ id, text, index, uniq_key, line_id, moveCard, secondary_color }) => {

	console.log('Card', id, text, index, uniq_key, line_id, moveCard)

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: uniq_key,
        hover(item, monitor) {

			if (!ref.current) {
			    return;
			}

			if (uniq_key !== item.uniq_key) return;

			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return;

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			    return;
			};

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			    return;
			};

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex, uniq_key, line_id);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: uniq_key, id, index, uniq_key, line_id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.1 : 1;
    drag(drop(ref));

    var backgroundColor = 'white';

	const style = {
		borderRadius : 8,
		border: '1px solid gray',
		margin : 3,
		backgroundColor,
		cursor: 'move',
	};

    return (
		<div ref={ref} style={{ ...style, opacity, backgroundColor: secondary_color, display: 'flex', flexDirection: 'row' }}>
			<div style={{ padding: 3, backgroundColor: 'black', color: 'white', width: '10%', textAlign: 'center', borderTopLeftRadius: 7, borderBottomLeftRadius: 7  }}>{index + 1}</div>
			<div style={{ width: '90%', padding: 3 }} >{text}</div>
		</div>
	);
};



const Dustbin = ({ accept, lastDroppedItem, line_id, onDrop }) => {

	const [{ isOver, canDrop }, drop] = useDrop({
		accept,
		drop: onDrop,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		})
	});

	const isActive = isOver && canDrop;
	let backgroundColor = 'grey';
	if (isActive) {
		backgroundColor = 'red';
	} else if (canDrop) {
		backgroundColor = 'grey';
	};

	var style = {
		borderRadius : 10,
		border: '1px solid gray',
		padding: 3
	};

	return (
		<div ref={drop} style={{ ...style, backgroundColor, marginTop : 10, color : 'white', textAlign : 'center'}}>
			{isActive ? '🤢' : 'Supprimer'}
			{lastDroppedItem && (<p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>)}
		</div>
	);
};


