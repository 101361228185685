import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, Breadcrumbs, Avatar} from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import Resizer from 'react-image-file-resizer';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';

import { UploadImage, GetImagePath } from '.././Utils';

import { ShakiContext } from '../store/ShakiProvider';
import { UsersContext } from '../store/UsersProvider';
import { ShopsContext } from '../store/ShopsProvider';

function User() {

	const { setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { user, user_roles, users_hash } = useContext(UsersContext);
	const { shops } = useContext(ShopsContext);

	var { id } = useParams();

	const [current_user, setCurrentUser] = useState(null)
	const [admin_users, setAdminUsers] = useState([])
	const [user_jobs, setUserJobs] = useState({});
	const [delivery_operators, setDeliveryOperators] = useState({});


	const classes = useStyles();

	useEffect(() => {
		setUserJobs(getRemoteConf('user_jobs'));
		setDeliveryOperators(getRemoteConf('delivery_operators'));
	}, []);


	useEffect(() => {

		// User admin
		var list = [];
		for (var i in users_hash) {
			if (users_hash[i].admin) list.push(users_hash[i]);
		}
		setAdminUsers(list);

		return firebase.firestore().collection('users').doc(id).onSnapshot((doc) => {
			setCurrentUser(doc.data());
		});

	}, [users_hash, id]);

	if (current_user === null) return null;

	const title = 'Utilisateur : ' + ((current_user.first_name === '') ? 'Anonymous' : current_user.first_name + ' ' + current_user.last_name);

	const handleInputChange = e => {
		const {name, value} = e.target;
		current_user[name] = value;
		setCurrentUser({...current_user});
		//update();
		console.log("save")
	}

	const handleRolesChange = (event) => {

		if (current_user.roles === undefined) current_user.roles = {};

		if (event.target.checked === false) {
			delete current_user.roles[event.target.name];
		} else {
			current_user.roles[event.target.name] = true;
		}

		setCurrentUser({ ...current_user});
		update();
	};

	const handleJobChange = (event) => {
		current_user.job = event.target.value;
		setCurrentUser({ ...current_user});
		update();
	};

	const handleEmployerChange = (event) => {
		current_user.employer = event.target.value;
		setCurrentUser({ ...current_user});
		update();
	};


	const handleShopChange = (event) => {
		current_user[event.target.name] = event.target.value;
		setCurrentUser({ ...current_user});
		update();
	};

	const handleDisabledChange = (event) => {
		current_user[event.target.name] = !event.target.checked;
		setCurrentUser({ ...current_user});
		update();
	};

	const handleAdminChange = (event) => {
		current_user[event.target.name] = event.target.checked;
		setCurrentUser({ ...current_user});
		update();
	};

	const fileChangedHandler = event => {

		var fileInput = false
		if (event.target.files[0]) {
			fileInput = true
		}

		if (fileInput) {

			Resizer.imageFileResizer(event.target.files[0], 1000, 1000, 'JPEG', 90, 0, (file) => {

				UploadImage('users', 'main', current_user.id, file, (path) => {
					setOpenSnackBar(true);
				});

			}, 'file');
		}
	}

	const update = () => {
		firebase.firestore().collection('users').doc(current_user.id).update(current_user);
		setOpenSnackBar(true);
		return true;
	}

	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 20 }}>
				<Link to='/'>Accueil</Link>
				<Link to='/users'>Utilisateurs</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ marginBottom: 40 }}>{title}</h2>

			<div>

				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap><b>Actif :</b></Typography>
					<FormControl component="fieldset">
						<FormGroup>
							<FormControlLabel
								control={<Switch checked={(current_user.disabled !== undefined) ? !current_user.disabled : true} onChange={handleDisabledChange} name='disabled' />}
							/>
						</FormGroup>
					</FormControl>

				</div>

				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap style={{ marginBottom: 20 }}><b>Identité :</b></Typography>
					<div style={{ marginBottom: 10 }}>

						<div style={{ marginBottom: 20 }}>
							<label htmlFor='picture'>
								<input id='picture' type="file" onChange={fileChangedHandler} style={{ display: 'none' }}/>
								<Avatar src={GetImagePath(current_user.images, 'main')} className={classes.large} component="span" />
							</label>

							<TextField
								id="first_name"
								name='first_name'
								value={current_user.first_name}
								label='Prénom'
								onChange={handleInputChange}
								style={{ width: 200, marginLeft: 10 }}
							/>
							<TextField
								id="last_name"
								name='last_name'
								value={current_user.last_name}
								label='Nom'
								onChange={handleInputChange}
								style={{ width: 200, marginLeft: 10 }}
							/>
						</div>

						<div>
							<TextField
								disabled={true}
								id="phone_number"
								name='phone_number'
								value={current_user.phone_number}
								label='Numéro de téléphone'
								style={{ width: 200, marginLeft: 60 }}
							/>

							<TextField
								id="card_number"
								name='card_number'
								value={current_user.card_number}
								label='Numéro de badge'
								onChange={handleInputChange}
								style={{ width: 200, marginLeft: 10 }}
							/>
							<TextField
								id="pin_code"
								name='pin_code'
								value={current_user.pin_code}
								label='Code pin'
								onChange={handleInputChange}
								style={{ width: 200, marginLeft: 10 }}
							/>
						</div>

					</div>
				</div>

				{(current_user.parent_user === undefined || current_user.parent_user === '') &&
				<div style={{ marginBottom: 40 }}>
					<Typography variant='h6' noWrap><b>Administrateur :</b></Typography>
					<FormControl component='fieldset'>
						<FormGroup>
							<FormControlLabel
								control={<Switch checked={(current_user.admin !== undefined) ? current_user.admin : false} onChange={handleAdminChange} name='admin' />}
							/>
						</FormGroup>
					</FormControl>
				</div>
				}

				{(current_user.admin === undefined || current_user.admin === false) &&
				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap><b>Compte parent : </b></Typography>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="parent_user">Compte parent</InputLabel>
						<Select
							native
							value={current_user.parent_user}
							onChange={handleShopChange}
							inputProps={{
								name: 'parent_user',
								id: 'parent_user',
							}}
						>
							<option aria-label="None" value='' />
							{admin_users.sort((a, b) => {
								if (a.first_name < b.first_name) return -1;
								if (a.first_name > b.first_name) return 1;
								return 0;
							}).map((row) => (
								<option key={row.id} value={row.id}>{row.first_name} {row.last_name}</option>
							))}
						</Select>
					</FormControl>
				</div>
				}

				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap><b>Employeur: </b></Typography>

					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="employer">Employeur</InputLabel>
						<Select
							native
							value={current_user.employer}
							onChange={handleEmployerChange}
							inputProps={{
								name: 'employer',
								id: 'employer',
							}}
						>
							<option aria-label='' value='' />
							<option value='Luna Food'>Luna Food</option>
							{Object.entries(delivery_operators).map((operator, i) => (
								<option value={operator[1].name}>{operator[1].name}</option>
							))}
						</Select>
					</FormControl>

				</div>

				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap><b>Rôle: </b></Typography>

					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="shop_owner">Rôle</InputLabel>
						<Select
							native
							value={current_user.job}
							onChange={handleJobChange}
							inputProps={{
								name: 'job',
								id: 'job',
							}}
						>
							<option aria-label='' value='' />
							{Object.entries(user_jobs).map((row, index) => (
								<option value={row[0]}>{row[1]}</option>
							))}
							{Object.entries(delivery_operators).map((operator, i) => (
								<option value={operator[0]}>Livreur {operator[1].name}</option>
							))}
						</Select>
					</FormControl>
				</div>

				{(current_user.admin) &&
				<div>
					<Typography variant="h6" noWrap><b>Droits</b></Typography>
					<FormControl component="fieldset">
						<FormGroup>
							{user_roles.map((row, index) => (
								<FormControlLabel
									key={row}
									control={<Switch checked={(current_user.roles !== undefined && current_user.roles[row] !== undefined) ? current_user.roles[row] : false} onChange={handleRolesChange} name={row} />}
									label={row}
								/>
							))}
						</FormGroup>
					</FormControl>
				</div>
				}

				{current_user.toto &&
				<div style={{ marginBottom: 40 }}>
					<Typography variant="h6" noWrap><b>Client associé : </b></Typography>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor="shop_owner">Clients</InputLabel>
						<Select
							native
							value={current_user.shop_owner}
							onChange={handleShopChange}
							inputProps={{
								name: 'shop_owner',
								id: 'shop_owner',
							}}
						>
							<option aria-label="None" value="" />
							{Object.values(shops).map((row) => (
								<option key={row.id} value={row.id}>{row.name}</option>
							))}
						</Select>
					</FormControl>
				</div>
				}

				<Button variant="outlined" color="default" onClick={(e) => {update()}}>
					Enregistrer
				</Button>

			</div>
		</div>
	);
}

export default React.memo(User);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	large: {
		width: 50,
		height: 50,
	},
});
