import React from 'react';

import { } from '@material-ui/core';
import TemplateLine from './TemplateLine';


function TemplateBloc(row) {

	var register = row.register;
	var index = row.index;
	var field = row.field;

	const Brand = (row) => {
		return (
			<div style={{ backgroundColor: '#000', color: '#fff', padding: 5, paddingLeft: 10, marginTop: 20, borderRadius: 15 }}>
				{row.category}
			</div>
		);
	}

	const Category = (row) => {
		return (
			<div style={{ borderBottom: '1px solid black', padding: 5, paddingLeft: 10, marginTop: 20 }}>
				{row.category}
			</div>
		);
	}


	const items = [];
	var sort_brand = Object.entries(row.recipes).sort();
 	for (var i in sort_brand) {

		items.push(<Brand key={sort_brand[i][0]} category={sort_brand[i][0]} />);

		var sort_category = Object.entries(sort_brand[i][1]).sort();
		for (var j in sort_category) {

			items.push(<Category key={sort_brand[i][0] + ' ' + sort_category[j][0]} category={sort_category[j][0]} />);

			var sort_sku = Object.entries(sort_category[j][1]).sort();
			for (var k in sort_sku) {
				var product = sort_sku[k][1];
				items.push(<TemplateLine key={product.sku} product={product} field={field} index={index} register={register} />);
			}
		}
	}

	return items;
}

export default React.memo(TemplateBloc);

